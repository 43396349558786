<template>
  <div style="min-height: 100vh; flex: 1;">
    <topNavBar :leftIcon="true" />

    <div id="searchPage" class="base-input v-w-full v-p-10 v-pr-10 v-rounded-10">
      <van-search
        v-model="getRealValue"
        :placeholder="$t('search-search_product')"
        :left-icon="''"
        shape="round"
        style="height: 11vw; padding: 0 3vw; font-size:3.73333vw"
        @input="onSearchInput"
      />
      <div class="k-text v-text-12 v-mt-10 v-pl-20">
        {{ $t("search-search_result") }}
      </div>

      <div class="result-list">
        <div v-for="item in filteredProductList" :key="item">
          <div
           class="v-v-rounded-10 v-px-14 v-py-8 v-m-10 flex items-center text-center justify-between"
            @click="$SkipPage(item.keyName)"
          >
            <div>
              <span class="k-text">{{ item.keyName }}</span>
              <span class="v-pl-20 text-[#dcdcdc] v-text-12">{{
                item.open
              }}</span>
            </div>
            <van-icon name="arrow" style="font-size: 1.55rem" />
          </div>
        </div>
      </div>
      <div v-if="filteredProductList.length == 0">
        <noRecord />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { store } from "@/assets/store/data.js";
import topNavBar from "@/pages/components/common/topNavBar.vue";
import noRecord from "@/pages/components/common/noRecord.vue";

const { appContext } = getCurrentInstance()
const { t } = useI18n();
const router = useRouter();
const getRealValue = ref("");
const productList = appContext.config.globalProperties.$lineData;
const filteredProductList = ref([]);

// 避免中文输入法和js enter回车提交冲突
function debounce(func, delay) {
  let timer;
  return function (...args) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

const onSearchInput = debounce(() => {
  if (getRealValue.value.trim() === "") {
    filteredProductList.value = productList;
  } else {
    filteredProductList.value = productList.filter((item) =>
      item.keyName.toLowerCase().includes(getRealValue.value.toLowerCase())
    );
  }
}, 100); 
</script>

<style scoped></style>
