<template>
  <div id="profitLoss">
    <div class="profit-loss v-rounded-10 v-mt-20 v-mb-10 v-p-16 base-shadow">
      <div v-if="curTab !== 2">
        <div class="k-text text-center v-mb-20">
          {{ $t("order-profit_loss") }}
        </div>
        <div class="text-center font-bold text-xl v-mt-10 v-mb-20">
          <span :class="isProfit ? 'text-kline-up' : 'text-kline-down'">{{
            netValue
          }}</span>
        </div>
        <div class="flex items-center justify-between v-mt-2">
          <div class="sub-text v-text-12">
            {{ $t("public-text-balance") }}
          </div>
          <div class="sub-value v-text-16 font-bold">
            {{ userInfo.Gold }}
          </div>
        </div>
        <div class="flex items-center justify-between v-mt-2">
          <div class="sub-text v-text-12">
            {{ $t("order-current_margin") }}
          </div>
          <div class="sub-value v-text-16 font-bold">
            {{ currentMagin }}
          </div>
        </div>
        <div class="flex items-center justify-between v-mt-2">
          <div class="sub-text v-text-12">
            {{ $t("order-risk_rate") }}
          </div>
          <div class="sub-value v-text-16 font-bold">{{ riskRate }}%</div>
        </div>
      </div>

      <div v-else>
        <div class="k-text text-center v-mb-20">
          {{ $t("order-total_profit_loss") }}
        </div>
        <div class="text-center font-bold text-xl v-mt-10 v-mb-20">
          <span :class="isNetProfit ? 'text-kline-up' : 'text-kline-down'">{{
            allNetValue
          }}</span>
        </div>
        <div class="flex items-center justify-between v-mt-2">
          <div class="sub-text v-text-12">
            {{ $t("public-text-balance") }}
          </div>
          <div class="sub-value v-text-16 font-bold">
            {{ userInfo.Gold }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  curTab: {
    type: Number,
    default: 0,
  },
});

const store = useStore();
const userInfo = computed(() => store.getters["userInfo"]);
const netValue = ref("3707.22");
const currentMagin = ref("210");
const riskRate = ref("55767919474.9105");
const isProfit = computed(() => netValue.value > 0);
const isNetProfit = computed(() => allNetValue.value > 0);
const allNetValue = ref("-480705207025.923");

//Prepaid for API return
// watch(yourDataSource, (newValue) => {
//   netValue.value = newValue;
// });
</script>

<style scoped></style>
