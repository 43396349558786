<template>
  <div style="min-height: 100vh; flex: 1">
    <topNavBar :leftIcon="true" :headerTitle="$t('mine-changePassword')" />

    <van-form @submit="changePassword" class="v-mt-20">
      <van-cell-group
        inset
        class="v-text-14 base-shadow bg-general"
      >
        <van-field
          v-model="password"
          class="bg-general"
          style="font-size: 3.26667vw; padding: 4vw 3vw;"
          type="password"
          :name="$t('login-enterPassword')"
          :placeholder="$t('login-enterPassword')"
          :rules="[{ required: true, message: $t('login-enterPassword') }]"
        />
        <van-field
          v-model="retypePassword"
          class="bg-general"
          style="font-size: 3.26667vw; padding: 4vw 3vw"
          type="password"
          :name="$t('public-retypePassword')"
          :placeholder="$t('public-retypePassword')"
          :rules="[{ required: true, message: $t('public-retypePassword') }]"
        />
        <van-field
          v-model="verifyCode"
          class="bg-general"
          style="font-size: 3.26667vw; padding: 4vw 3vw"
          :name="$t('regsiter-enterVerityCode')"
          :placeholder="$t('regsiter-enterVerityCode')"
        >
          <template #button>
            <van-button
              size="small"
              class="btn-blue v-rounded-4 v-px-20 v-py-14 v-text-12 v-border-0"
              :disabled="countdown > 0"
              @click="sendCode"
              >{{
                countdown > 0 ? `${countdown}s` : $t("public-btn-send")
              }}</van-button
            >
          </template>
        </van-field>
      </van-cell-group>

      <div class="flex justify-center text-center">
        <div class="w-[80%] v-my-20">
          <van-button
            round
            block
            class="btn-blue v-h-40 v-text-14 v-border-0"
            native-type="submit"
            @click="changePassword"
          >
            {{ $t("public-text-confirm") }}
          </van-button>
        </div>
      </div>
    </van-form>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { showToast } from "/node_modules/vant";
import topNavBar from "@/pages/components/common/topNavBar.vue";

const { t } = useI18n();
const password = ref("");
const retypePassword = ref("");
const verifyCode = ref("");

let countdown = ref(0);
const sendCode = () => {
  if (countdown.value === 0) {
    countdown.value = 59; // 59 seconds countdown
    const timer = setInterval(() => {
      countdown.value--;
      if (countdown.value === 0) {
        clearInterval(timer);
      }
    }, 1000);
  }
  showToast({
    message: t("systemHint-code_send_succesful"),
    icon: "success",
  });
};

const showMessage = (icon, message) => {
  showToast({
    type: icon.toLowerCase(),
    message,
    duration: 1000,
  });
};

const changePassword = () => {
  console.log("1111");
  if (!password.value && !retypePassword.value && !verifyCode.value) {
    showMessage("fail", t("systemHint-plsCompleted"));
  } else if (password.value !== retypePassword.value) {
    showMessage("fail", t("systemHint-pwdInconsistent"));
  } else if (password.value === retypePassword.value && !verifyCode.value) {
    showMessage("fail", t("systemHint-incorrectVerifyCode"));
  } else {
    showMessage("success", t("systemHint-changeSuccess"));
  }
};
</script>

<style scoped>

</style>
