<template>
  <div style="min-height:100vh; flex:1;">
    <topNavBar :leftIcon="true" :headerTitle="$t('mine-account_details')" />

    <van-pull-refresh
        v-model="loading"
        @refresh="onRefresh"
        :pulling-text="$t('public-text-scroll_down_refresh')"
        :loosing-text="$t('public-text-loose-to-refresh')"
        :loading-text="$t('public-text-loading')"
    >
        <div class="bg-main v-p-16">
            <div class="k-text font-bold text-[20px] v-mb-10">USD</div>
            <div class="flex items-center">
                <div class="flex-1">
                    <div class="text-color-7 v-text-12">{{ $t('public-text-available') }}</div>
                    <div class="text-kline-up v-text-16">{{ userInfo.Gold }}</div>
                </div>
                <div class="flex-1">
                    <div class="text-color-7 v-text-12">{{ $t('public-text-freeze') }}</div>
                    <div class="k-text v-text-16">{{ userInfo.FreezeGold }}</div>
                </div>
            </div>
        </div>

        <van-list>
            <div class="text-color-7 v-text-12 v-m-8">
                <div class="v-mb-10 k-text v-text-14 v-pl-10">{{ $t('accHistory-financial_records') }}</div>
            
                <div 
                    class="v-p-10 v-mb-8 bg-general v-rounded-8" 
                    v-for="(item, index) in financialRecords" 
                    :key="index"
                >
                    <div class="flex items-center justify-between">
                        <div>
                            <span class="v-pr-10">{{ $t('withdraw-coin_amount') }}</span>
                            <span class="k-text">{{ item.quantity }}</span>
                        </div>
                        <div>
                            <span class="v-pr-10">{{ $t('accHistory-records') }}</span>
                            <span class="k-text">{{ item.memo }}</span>
                        </div>
                    </div>

                    <div class="v-mt-8">
                        <span class="v-pr-10">{{ $t('public-text-date') }}</span>
                        <span class="k-text">{{ item.time }}</span>
                    </div>
                </div>

                <div v-if="financialRecords.length == 0">
                    <noRecord />
                </div>
            </div>
        </van-list>
    </van-pull-refresh>
  </div>
</template>

<script setup>
import { reactive, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from 'vuex';
import { store } from "@/assets/store/data.js";
import topNavBar from "@/pages/components/common/topNavBar.vue";
import noRecord from "@/pages/components/common/noRecord.vue"

const { t } = useI18n();
const router = useRouter();
const storeA = useStore();
const userInfo = computed(() => storeA.getters['userInfo']);
const financialRecords = ref(store.value.financialRecords)

const loading = ref(false);
const onRefresh = () => {
  setTimeout(() => {
    loading.value = false;
  }, 800);
};
</script>

<style scoped></style>
