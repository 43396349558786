import { createApp } from 'vue'
import App from './App.vue'
import router from '@/assets/js/pageNews.js'
import i18n from '@/assets/js/i18n.js'
import '@/assets/css/index.css'
import '@/assets/css/theme.css'
import Vant from '/node_modules/vant'
import 'vant/lib/index.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'icofont/dist/icofont.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import store from '@/assets/store/store.ts'
import chart from './api/chart'
import $ from 'jquery'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(chart);
app.use(i18n)
app.use(ElementPlus)
app.use(Vant)
app.use($)

app.mount('#app')


// 全局导入ElementPlus Icons
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}