<template>
  <div id="withdraw">
    <van-form @submit="submitWithdraw">
      <div
        class="bg-general v-mx-10 v-pb-10 v-rounded-bottom custom-bottom-shadow"
      >
        <van-cell-group inset class="bg-general">
          <div class="customBottomLine">
            <van-field
              readonly
              clickable
              :label="$t('bind-currency')"
              v-model="currencyLabel"
              :value="currencyLabel"
              :placeholder="currencyLabel"
              class="bg-general"
              right-icon="arrow"
              style="font-size: 3.2vw"
              @click="showCurrencyPicker = true"
            />
            <!-- Currency Picker -->
            <van-popup
              v-model:show="showCurrencyPicker"
              position="bottom"
              round
            >
              <van-picker
                show-toolbar
                :columns="currencyOptions"
                :cancel-button-text="$t('public-text-cancel')"
                :confirm-button-text="$t('public-text-confirm')"
                @cancel="showCurrencyPicker = false"
                @confirm="onConfirm"
              />
            </van-popup>
          </div>

          <div class="customBottomLine">
            <van-field
              readonly
              clickable
              :label="$t('withdraw-coin_address')"
              v-model="valueAddress"
              :value="valueAddress"
              :placeholder="
                valueAddress ? valueAddress : $t('withdraw-select_addess')
              "
              :class="
                valueAddress !== $t('withdraw-select_addess')
                  ? ''
                  : 'hasVal'
              "
              class="bg-general"
              right-icon="arrow"
              style="font-size: 3.2vw"
              @click="showAddressPicker = true"
            />
            <!-- Address Picker -->
            <van-popup v-model:show="showAddressPicker" position="bottom" round>
              <van-picker
                show-toolbar
                :columns="columnAddress"
                :cancel-button-text="$t('public-text-cancel')"
                :confirm-button-text="$t('public-text-confirm')"
                @cancel="showAddressPicker = false"
                @confirm="onConfirmAddress"
              />
            </van-popup>
          </div>
          <div class="customBottomLine">
            <van-field
              style="font-size: 3.2vw"
              v-model="withdrawAmount"
              type="number"
              class="bg-general"
              :label="$t('withdraw-coin_amount') + `(USD)`"
              :placeholder="$t('withdraw-coin_amount')"
            />
          </div>
          <div class="customBottomLine">
            <van-field
              style="font-size: 3.2vw"
              v-model="remarks"
              class="bg-general"
              :label="$t('public-text-remarks')"
              :placeholder="$t('public-text-remarks')"
            />
          </div>

          <div class="v-px-10 v-text-12 k-text">
            <div class="flex items-center justify-between v-mt-10">
              <span>{{ $t("withdraw-handling_fee") }}</span>
              <span>{{ handlingFee }}</span>
            </div>
            <div class="flex items-center justify-between v-mt-10">
              <span>{{ $t("withdraw-expected") }}</span>
              <span>{{ expectedValue }} {{ formatText(currencyLabel) }}</span>
            </div>
            <div class="flex items-center justify-between v-mt-10">
              <span>{{ $t("public-text-balance") }}</span>
              <span>{{ userInfo.Gold + ` USD` }}</span>
            </div>
          </div>
          <div class="flex justify-center v-my-20 v-px-12">
            <van-button
              size="normal"
              round
              class="w-[70%] btn-blue v-border-0"
              native-type="submit"
              >{{ $t("withdraw-btn-submit") }}</van-button
            >
          </div>
        </van-cell-group>
      </div>
    </van-form>

    <!-- 历史记录列表 -->
    <WithdrawRecord :curType="$t('deposit-digitai-currency')" />
  </div>
</template>

<script setup>
import { reactive, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { store } from "@/assets/store/data.js";
import { showToast } from "/node_modules/vant";
import WithdrawRecord from "@/pages/components/withdraw/components/withdrawRecord.vue";

const { t } = useI18n();
const router = useRouter();
const storeA = useStore();
const userInfo = computed(() => storeA.getters["userInfo"]);

//currency selection
const currencyOptions = ref(store.value.currencyOptions);
const currencyLabel = ref(currencyOptions.value[0].text);
const showCurrencyPicker = ref(false);
const onConfirm = (val) => {
  currencyLabel.value = val.selectedOptions[0].text;
  showCurrencyPicker.value = false;
};

// coin address selection
const columnAddress = ref([
  { text: "aaaa", value: "aaaa" },
  { text: "bbbb", value: "bbbb" },
]);
const valueAddress = ref("");
const showAddressPicker = ref(false);
const onConfirmAddress = (val) => {
  valueAddress.value = val.selectedOptions[0].text;
  showAddressPicker.value = false;
};

const formatText = (str) => {
  return str.substring(0, 3);
};

const formatValue = (val) => {
  return val.toFixed(2);
};

const withdrawAmount = ref(null);
const remarks = ref("");
const handlingFee = computed(() => (withdrawAmount.value * 0.1).toFixed(2));
const expectedValue = computed(() => (withdrawAmount.value * 0.2).toFixed(2));

const submitWithdraw = () => {
  if (!currencyLabel.value || !valueAddress.value || !withdrawAmount.value) {
    showToast({
      message: t("systemHint-missing_params-or-val-error"),
      icon: "fail",
    });
  } else {
    showToast({
      message: t("systemHint-operation_successful"),
      icon: "success",
    });
    clearForm();
  }
};

const clearForm = () => {
  valueAddress.value = t("withdraw-select_addess");
  withdrawAmount.value = "";
  remarks.value = "";
};
</script>

<style scoped>
.box-shadow-bottom {
  box-shadow: 0 2px 4px rgba(161, 161, 161, 0.09);
}
</style>
