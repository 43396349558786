<template>
  <div id="deposit">
    <van-form @submit="onSubmit">
      <van-cell-group class="bg-main">
        <div class="bg-general v-mx-10 v-pb-16 v-rounded-bottom custom-bottom-shadow">
          <div class="v-text-16 ml-20 k-text">{{ $t("deposit-select_network") }}</div>

          <div
            class="input-bg v-h-42 leading-42 v-rounded-base v-m-16 v-px-10"
          >
            <van-field
              readonly
              clickable
              v-model="coinLabel"
              :value="coinLabel"
              :placeholder="coinLabel"
              class="custom-field input-bg v-h-40 leading-40"
              @click="showCoinPicker = true"
            />
            <!-- Coin Picker -->
            <van-popup v-model:show="showCoinPicker" round position="bottom">
              <van-picker
                :columns="coinList"
                :cancel-button-text="$t('public-text-cancel')"
                :confirm-button-text="$t('public-text-confirm')"
                @cancel="showCoinPicker = false"
                @confirm="onConfirm"
              />
            </van-popup>
          </div>
          <div class="flex justify-center">
            <img :src="getWalletCode" class="mx-auto" width="320" height="320" style="width: 160px; height: 160px;" />
          </div>
          <div class="k-text text-center v-text-12 v-mt-18 px-5">
            {{ $t("bind-wallet-address") }}
          </div>
          <div class="k-text text-center v-text-14 v-w-full break-words">
            {{ walletAddress }}
          </div>
          <div class="text-center v-text-14 mt-1 px-5" @click="copyContent">
            <el-icon :size="26"><CopyDocument /></el-icon>
          </div>
        </div>

      <!-- 上传凭证 -->
      <div class="bg-main">
        <div class="v-my-10 v-px-20">
          <div class="v-text-12 k-text">{{ $t("deposit-coin-number") }}</div>
          <div class="input-bg base-input v-w-full v-mt-10">
            <van-field
              class="input-bg v-mx-10"
              style="font-size: 3.26667vw"
              v-model="valueCoin"
              :placeholder="$t('deposit-coin-placeholder')"
            />
          </div>

          <div class="flex flex-col items-center v-mt-20">
            <van-uploader
              class="mx-auto"
              v-model="certImage"
              :max-count="1"
              :after-read="afterRead"
              @click-preview="onClickPreview"
            ></van-uploader>
            <van-popup position="center">
              <van-image
                :src="previewCertImageSrc"
                style="object-fit: contain"
              />
            </van-popup>
          </div>
          <div class="k-text text-center v-text-12 px-5">
            {{ $t("doposit-upload-cert") }}
          </div>
        </div>

        <div class="flex justify-center v-h-40 mx-auto w-[80%] v-my-10">
          <van-button class="btn-blue v-border-0" size="large" round native-type="submit">{{
            $t("public-btn-submit")
          }}</van-button>
        </div>
      </div>
    </van-cell-group>
  </van-form>

    <!-- 历史记录列表 -->
    <DepositRecord :curType="$t('deposit-digitai-currency')" />
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { store } from "@/assets/store/data.js";
import { showToast } from "/node_modules/vant";
import DepositRecord from "@/pages/components/deposit/components/depositRecord.vue";

const { t } = useI18n();
const router = useRouter();
const getWalletCode = ref(require("@/assets/image/qr-code.png"));
const walletAddress = ref("TTyvAVEzGgd1ZYm74LYe3uiiz72qcPCutu");
const coinList = ref(store.value.coinList);
const coinLabel = ref(coinList.value[0].text);
const showCoinPicker = ref(false);
const valueCoin = ref("");
const certImage = ref([]);
const previewCertImageSrc = ref("");

const onConfirm = (val) => {
  coinLabel.value = val.selectedOptions[0].text;
  showCoinPicker.value = false;
};

const copyContent = () => {
  const address = walletAddress.value
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(address);
    showToast({
        message: t("systemHint-copySuccess"),
        icon: "success",
    });
  } else {
    const textArea = document.createElement("textarea");
    textArea.value = address;
    textArea.style.position = "fixed";
    textArea.style.opacity = 0;
    document.body.appendChild(textArea);
    textArea.select();
    const successful = document.execCommand('copy');
    if (successful) {
        showToast({
            message: t("systemHint-copySuccess"),
            icon: "success",
        });
    } 
    document.body.removeChild(textArea);
  }
};

const afterRead = (file) => {
  certImage.value = [file];
};

const onClickPreview = (file) => {
  previewCertImageSrc.value = file.content || file.url;
};

const onSubmit = () => {
  if (!coinLabel.value || !valueCoin.value || !certImage.value) {
    showToast({
      message: t("systemHint-missing_params-or-val-error"),
      icon: "fail",
    });
  } else {
    showToast({
      message: t("systemHint-operation_successful"),
      icon: "success",
    });
    clearForm();
  }
};

const clearForm = () => {
  coinLabel.value = "";
  valueCoin.value = "";
  certImage.value = [];
};

onMounted(() => {});
</script>

<style scoped>
.base-input {
  border-radius: 5.33333vw;
  padding-top: 2.13333vw;
  padding-bottom: 2.13333vw;
  height: 10.66667vw;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.van-cell {
  padding: 0!important;
}
</style>
