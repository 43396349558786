<template>
    <div style="min-height: 100vh; flex: 1;">
        <topNavBar :leftLanguageIcon="true" :headerTitle="$t('app-title') + '(' + $t('app-title-2') + ')'"
            :rightIcon="true" :iconName="'Search'"
            :rightIconStyle="'height:8.4444vw; width:8vw; border-radius: 2vw; box-shadow: 0 0 3vw #a1a1a12e;'"
            :handleClick="navToSearch" />
        <div id="home" class="v-px-6">
            <div class="v-mt-10 v-mb-10 v-px-6 flex v-text-14 overflow-auto headTop">
                <div :class="['headtab', { active: indexTab === index + 1 }]" v-for="(time, index) in TabArr"
                    :key="index" @click="setTabBg(index, time)">
                    {{ time }}
                </div>
            </div>

            <div id="list">
                <div v-for="(item, index) in ArrData" :key="index">
                    <div style="
                        width: 100%;
                        padding: 1.33333vw 3.2vw;
                        margin-top: 1.06667vw;
                        border-radius: 2.66667vw;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        height: 16vw;
                      " class="base-shadow list-main" @click="$SkipPage(item.keyName)">
                        <div style="display: flex">
                            <div style="display: flex; align-items: center">
                                <img src="@/assets/image/NZDUSD.png" style="width: 37.475px; height: 37.475px" />
                            </div>
                            <div style="
                                  display: flex;
                                  align-items: center;
                                  padding-left: 2.66667vw !important;
                                  font-size: 4.26667vw;
                                ">
                                {{ item.keyName }}
                            </div>
                        </div>
                        <div style="display: flex; align-items: center">
                            <div class="" v-if="item.status == 0">
                                <div style="text-align: center; font-size: 3.7vw">
                                    <div>{{ item.open }}</div>
                                    <div :class="{ 'positive': item.state == 0, 'negative': item.state != 0 }">
                                        {{ item.state == 0 ? "+" : "-" }}{{ item.Pct }}
                                    </div>
                                </div>
                            </div>
                            <div class="flex-1 v-w-110" v-else>
                                <div class="closed">{{ $t('public-text-close') }}</div>
                            </div>
                        </div>
                        <div style="display: flex; align-items: center">
                            <div :id="'k-line-chart' + (index + 1)" class="klChart" style="text-align: center">
                                <van-loading class="_loading" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <tabBar />
    </div>
</template>

<script setup>
import { onMounted, ref, nextTick, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import topNavBar from "@/pages/components/common/topNavBar.vue";
import tabBar from "@/pages/components/common/tab-bar.vue";
import { getAreaData } from "@/api/chart";

const router = useRouter();
const ETPName = ref("");
const { t } = useI18n();
const indexTab = ref();
const ArrData = ref(getAreaData());

const TabArr = ref(["Forex", "Crypto", "Metal", "Oil", "CFD"]);

onMounted(() => { });

function setTabBg(i, open) {
    if ((i + 1) == indexTab.value) {
        indexTab.value = '';
        ETPName.value = '';
    } else {
        indexTab.value = i + 1;
        ETPName.value = open;
    }
}

watch(ETPName, () => {
    ArrData.value = getAreaData(ETPName.value);
});

const navToSearch = () => {
    router.push({ name: 'SearchProduct' })
};
</script>

<style scoped>
.headTop::-webkit-scrollbar {
    display: none;
    /* 隐藏 Chrome, Safari 和 Opera 中的滚动条 */
}

.headtab {
    margin-right: 2.66667vw;
    padding: 1vw 4.26667vw;
    border-radius: 8vw;
    font-weight: 800;
    font-size: 3.2vw;
    /* background: #f3f4f6;
  color: black; */
    white-space: nowrap;
}

.klChart {
    width: 24vw;
    height: 6.4vw;
    text-align: center;
}

.closed {
    font-size: 3.2vw;
    /* color: #fff; */
    border-radius: 2.13333vw;
    font-weight: 700;
    text-align: center;
    padding: 1.06667vw 1.6vw;
}
</style>
