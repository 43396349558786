import { setFakeToken, removeFakeToken, getToken } from '../util.js'
setFakeToken('fakeToken');

const state = () => ({
    // 用户基本信息
    userInfo: {
        UserId: '',
        Email: '',
        Uid: '', 
        Gold: 0, //余额
        FreezeGold: 0, //凍結金額
        RealName: '', //真实姓名
        BindBankCard: [], //绑定银行卡 wallet
        BindCurrencyAddress: [], //绑定currency地址 wallet
        AuthenticationStatus: 0, // 身份验证 0未身份验证 1待审核 2已通过 3已拒绝
        IdVerificationTimeLimitDate: '', // 身份验证期限
        Password: '', //账号密码
        IsDemo: '', //0:正式，1:模拟账户
    },
    isLogin: getToken() ? true : false, //是否登录状态
});

const mutations = {
    SET_IS_LOGIN: (state, val) => {
        state.isLogin = val
    },
    // 用户信息
    SET_USER_INFO: (state, info) => {
        state.userInfo.UserId = info.UserId;
        state.userInfo.Email = info.Email;
        state.userInfo.Uid = info.Uid;
        state.userInfo.Gold = info.Gold;
        state.userInfo.FreezeGold = info.FreezeGold;
        state.userInfo.RealName = info.RealName;
        state.userInfo.AuthenticationStatus = info.AuthenticationStatus;
        state.userInfo.IdVerificationTimeLimitDate = info.IdVerificationTimeLimitDate;
        state.userInfo.Password = info.Password;
        state.userInfo.IsDemo = info.IsDemo ==  1 ? 1 : 0
    },
    SET_BIND_EMAIL: (state, val) => {
        state.userInfo.Email = val;
    },
    SET_UID: (state, val) => {
        state.userInfo.Uid = val;
    },
    SET_GOLD: (state, val) => {
        state.userInfo.Gold = val;
    },
    SET_BIND_FREEZE_GOLD: (state, val) => {
        state.userInfo.FreezeGold = val;
    },
    SET_BIND_REAL_NAME: (state, val) => {
        state.userInfo.RealName = val;
    },
    // 绑定银行卡
    SET_BIND_BANK_CARD: (state, val) => {
        state.userInfo.BindBankCard = val;
    },
    // 绑定currency地址
    SET_BIND_CURRENCY_ADDRESS: (state, val) => {
        state.userInfo.BindCurrencyAddress = val;
    },
    SET_AUTHENTICATION_STATUS: (state, val) => {
        state.userInfo.AuthenticationStatus = val;
    },
    RESET_USER_GOLD: (state, originalGold) => {
        state.userInfo.Gold = originalGold;
    }
};

const actions = {
    // 获取用户信息
    getUserInfo({ commit }) {
        return new Promise((resolve, reject) => {
            const response = {
                info: {
                    UserId: '1',
                    Email: '111@test.com',
                    Uid: '6959285729', 
                    Gold: '667079203626.52', //余额
                    FreezeGold: '99000',
                    RealName: 'RealAgent',
                    BindBankCard: [], 
                    BindCurrencyAddress: [], 
                    AuthenticationStatus: 0, 
                    Password: '123456',
                    IsDemo: '0' //正式
                }
            };
            if (response && response.info) {
                let info = response.info;
                commit('SET_USER_INFO', info);
                localStorage.setItem('userInfo', JSON.stringify(info));
                resolve(info);
            } else {
                reject(new Error('Failed to retrieve user info'));
            }
        });
    },
    // 获取 Demo 用户信息
    getDemoUserInfo({ commit }) {
        return new Promise((resolve, reject) => {
            const demoResponse = {
                info: {
                    UserId: '2',
                    Email: 'Simulation Account',
                    Uid: '1234567890',
                    Gold: '1000000',
                    FreezeGold: '0',
                    RealName: 'DemoAgent',
                    BindBankCard: [], 
                    BindCurrencyAddress: [], 
                    AuthenticationStatus: 1, 
                    Password: '',
                    IsDemo: '1' // Demo
                }
            };
            if (demoResponse && demoResponse.info) {
                let info = demoResponse.info;
                commit('SET_USER_INFO', info);
                localStorage.setItem('userInfo', JSON.stringify(info));
                resolve(info);
            } else {
                reject(new Error('Failed to retrieve demo user info'));
            }
        });
    },
    // Hardcode: 获取注册用户信息
    getRegUserInfo({ commit }) {
        let userInfo = JSON.parse(localStorage.getItem('userRegInfo')) || {};
        userInfo = {
            UserId: '3',
            Uid: '99887766', 
            Gold: '0', //余额
            FreezeGold: '0',
            RealName: '',
            BindBankCard: [], 
            BindCurrencyAddress: [], 
            AuthenticationStatus: 0, 
            IsDemo: '0', //正式
            ...userInfo
        };
        commit('SET_USER_INFO', userInfo);
        localStorage.setItem('userRegInfo', JSON.stringify(userInfo));
    },
    // 设置登录状态
    setIsLogin({ commit }, status) {
        commit('SET_IS_LOGIN', status);
    },
    // 设置登出状态
    setLogOut({ commit }) {
        return new Promise(resolve => {
            let nullData = {
                UserId: '',
                Email: '',
                Uid: '', 
                Gold: '0', //余额
                FreezeGold: '0', //凍結金額
                RealName: '',
                BindBankCard: [], //绑定银行卡 //wallet
                BindCurrencyAddress: [], //绑定currency地址 wallet
                AuthenticationStatus: 0, // 身份验证 0未身份验证 1待审核 2已通过 3已拒绝
                IdVerificationTimeLimitDate: '', // 身份验证期限
                IsDemo: '',
            }
            removeFakeToken()
            commit('SET_USER_INFO', nullData)
            localStorage.removeItem('userInfo')
            localStorage.removeItem('userRegInfo')
            commit('SET_IS_LOGIN', false)
            commit('SET_BIND_EMAIL', '')
            resolve()
        })
    },
    // 设置邮箱
    setEmail({ commit }, value) {
        commit('SET_BIND_EMAIL', value); 
    },
    // 设置UID
    setUid({ commit }, value) {
        commit('SET_UID', value);
    },
    // 设置余额
    setGold({ commit }, value) { 
        commit('SET_GOLD', value);
    },
    // 设置真实姓名
    setRealName({ commit }, value) {
        commit('SET_BIND_REAL_NAME', value);
    },
    getUserBindBank({ commit, state }) {
        return new Promise((resolve, reject) => {
            getBindBank()
                .then(response => {
                    commit('SET_BIND_BANK_CARD', response.List);
                    resolve(response);
                })
                .catch(error => {
                    reject(error); 
                });
        });
    },
};

const getters = {};

export default {
    state,
    actions,
    mutations,
    getters
};
