<template>
  <div id="login" style="min-height: 100vh; flex: 1">
    <topNavBar :leftIcon="true" :rightIcon="false" />
    <div class="k-text text-center text-xl leading-40 h-80">
      <span>{{ t("app-title") }}</span>
      <span>({{ t("app-title-2") }})</span>
    </div>

    <div class="v-px-24 v-pb-40">
      <el-form
        :label-position="'top'"
        label-width="auto"
        :model="registerForm"
        ref="formRef"
      >
        <el-form-item :label="$t('public-enterEmail')" class="blue-label">
          <el-input
            v-model="registerForm.Email"
            name="Email"
            style="font-size: 3.73333vw; border-radius: 8vw;"
            :placeholder="$t('login-enterEmail')"
            autocomplete="Email"
          />
        </el-form-item>
        <el-form-item :label="$t('public-password')" class="basic-label">
          <el-input
            v-model="registerForm.Password"
            name="Password"
            style="font-size: 3.73333vw; border-radius: 8vw;"
            :placeholder="$t('login-enterPassword')"
            type="password"
            autocomplete="Password"
          />
        </el-form-item>
        <el-form-item :label="$t('public-retypePassword')" class="basic-label">
          <el-input
            v-model="registerForm.retypePassword"
            style="font-size: 3.73333vw; border-radius: 8vw;"
            :placeholder="$t('login-enterPassword')"
            type="password"
          />
        </el-form-item>
        <el-form-item :label="$t('public-inviteCode')" class="basic-label">
          <el-input
            v-model="registerForm.inviteCode"
            style="font-size: 3.73333vw; border-radius: 8vw;"
            :placeholder="$t('regsiter-enterInviteCode')"
            type="number"
          />
        </el-form-item>
        <el-form-item :label="$t('public-verifyCode')" class="basic-label">
          <el-input
            v-model="registerForm.verifyCode"
            style="font-size: 3.73333vw; border-radius: 8vw;"
            :placeholder="$t('regsiter-enterVerityCode')"
            type="number"
          >
            <template #suffix>
              <img
                class="v-h-30 v-w-100"
                src="@/assets/image/verifyCode.png"
              />
            </template>
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            @click="submitForm"
            round
            class="btn-blue v-border-0"
            style="
              width: 100%;
              height: 10.66667vw;
              border-radius: 5.33333vw;
              font-size: 3.7222vw;
              margin-top: 4.66667vw
            "
          >
            {{ $t("regsiter-btn-register") }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import topNavBar from "@/pages/components/common/topNavBar.vue";
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { showToast } from "/node_modules/vant";

const { t } = useI18n();
const store = useStore()
const router = useRouter();
const registerForm = ref({
  Email: "",
  Password: "",
  retypePassword: "",
  inviteCode: "",
  verifyCode: "",
});

const formRef = ref(null);

const showMessage = (icon, message) => {
  showToast({
    type: icon.toLowerCase(),
    message,
    duration: 1000,
  });
};

// 提交表单
const submitForm = async () => {
  if (!registerForm.value) {
    showMessage("fail", t("systemHint-incorrectVerityCode"));
    return;
  }
  if (registerForm.value.Password !== registerForm.value.retypePassword) {
    showMessage("fail", t("systemHint-passwordsDoNotMatch"));
    return;
  }
  if (!registerForm.value.verifyCode) {
    showMessage("fail", t("systemHint-incorrectVerityCode"));
    return;
  }
  if(registerForm.value) {
    try {
      await validateForm();
      // 发起注册请求
      await registerUser(registerForm.value);
      console.log('registerForm.value', registerForm.value)
      
      const storeData = {
      Email: registerForm.value.Email,
      Password: registerForm.value.Password
    };
    console.log('storeData', storeData)
      store.commit('SET_USER_INFO', storeData); 
      localStorage.setItem('userRegInfo', JSON.stringify(storeData))
      router.push('/pages/login/loginIndex');
    } catch (error) {
      console.error('注册失败:', error);
    }
  }
};

// 表单验证
const validateForm = () => {
  return new Promise((resolve, reject) => {
    if (formRef.value) {
      formRef.value.validate((valid) => {
        if (valid) {
          resolve(true);
        } else {
          reject(false);
        }
      });
    } else {
      reject(false);
    }
  });
};

// 用户注册请求
const registerUser = async (form) => {
  // 这里填入实际的注册请求逻辑
  // 例如: await api.register(form);
};
</script>

<style scoped>
:deep(.el-input__wrapper) {
  border-radius: 5vw;
  height: 11vw;
  padding: 0 4vw;
  /* background: #f8f8f8 !important; */
  border: 0 solid;
  box-shadow: 0 0 0 0;
}

:deep(.el-input__wrapper.is-focus) {
  box-shadow: 0 0 0 0;
}

.text-container {
  font-size: 3.2vw;
  display: flex !important;
  justify-content: space-between !important;
  /* color: rgb(203 203 203 / 1); */
}

/* 为中等屏幕设置样式 */
@media (min-width: 600px) and (max-width: 1200px) {
  :deep(.el-input__wrapper) {
    margin: 3vw 0;
}
}
</style>
