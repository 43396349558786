<template>
    <van-config-provider :theme="theme">
        <router-view v-slot="{ Component }">
            <transition :name="ExcessName">
                <component :is="Component"></component>
            </transition>

            <van-dialog 
                v-model:show="showDialog"
                theme='round-button'
                class-name="restrict-class"
                @close="handleDialogClose" 
                :title="$t('title-welcome_to_use')"
                :message="$t('announce-text')  + setRedTxt($t('announce-text-demo'))"
                :confirm-button-text="confirmButtonText"
                :confirmButtonDisabled="confirmDisabled"
                allowHtml="true"
            />
        </router-view>
    </van-config-provider>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from "vue-i18n";
import { useStore } from 'vuex';
// import { showDialog } from '/node_modules/vant';

const { t } = useI18n();
const store = useStore();
const router = useRouter();
const ExcessName = ref('')
const showDialog = ref(false);
const confirmButtonText = ref(t('public-btn-accept') + ' (10)');
const confirmDisabled = ref(true)

const isLogin = computed(() => store.getters['isLogin'])
const theme = computed(() => store.getters.theme)
const userInfo = computed(() => {
  const userInfoString = localStorage.getItem('userInfo');
  return userInfoString ? JSON.parse(userInfoString) : null;
});
const isDemo = computed(() => userInfo.value ? userInfo.value.IsDemo : null);


onMounted(() => { 
    //过度效果
    router.beforeEach((to, from) => {
        updateTransition(to,from)
    })

    //获取主题颜色
    const savedTheme = localStorage.getItem('theme') || 'dark';
    document.documentElement.setAttribute('data-theme', savedTheme);
    store.dispatch('setTheme', savedTheme);

    //首次进入显示的弹窗
    showRestrictPopup()

    //获取用户信息
    if (!isLogin.value || !isDemo.value) {
        logOut()
        return
    }
    if (isLogin.value) {
        if (isDemo.value == 1) {
            store.dispatch('getDemoUserInfo')
        } else if (isDemo.value == 0) {
            store.dispatch('getUserInfo')
        } else {
            logOut()
            return
        }
    }
})

const logOut = () => {
    store.dispatch('setLogOut')
    router.push('/')
}

const setRedTxt = (val) => {
    return `<span class="text-kline-down">${val}</span>`
}

const showRestrictPopup = () => {
    if (!localStorage.getItem('isFirstVisit')) {
        showDialog.value = true; // 显示对话框

        // 10秒倒数
        let countdown = 10;
        const interval = setInterval(() => {
            countdown--;
            confirmButtonText.value = t('public-btn-accept') + ` (${countdown})`;

            //倒数结束更新按钮
            if (countdown === 0) {
                clearInterval(interval);
                confirmButtonText.value = t('public-btn-accept');
                confirmDisabled.value = false;
            }
        }, 1000);
        
        localStorage.setItem('isFirstVisit', 'false');
    }
};

const handleDialogClose = () => {
    showDialog.value = false;
};

function updateTransition(to,from){
    if (to.name == 'HomeIndex'){
        ExcessName.value = 'LfExcess'
    } else if (to.name == 'OrderIndex'){
        if(from.name == 'HomeIndex'){
            ExcessName.value = 'RgExcess'
        }else{
            ExcessName.value = 'LfExcess'
        }
    } else if (to.name == 'TradeIndex'){
        if (from.name == 'HomeIndex' || from.name == 'OrderIndex'){
            ExcessName.value = 'RgExcess'
        }else{
            ExcessName.value = 'LfExcess'
        }
    } else if (to.name == 'NewsIndex'){
        if (from.name == 'MineIndex'){
            ExcessName.value = 'LfExcess'
        }else{
            ExcessName.value = 'RgExcess'
        }
    } else if (to.name == 'MineIndex'){
        ExcessName.value = 'RgExcess'
    }else{
        ExcessName.value = 'LfExcess'
    }
}
</script>

<style>
html, body, #app {
    margin: 0 !important;
    /* background: white; */
    background-attachment: fixed;
    background-position: center top;
    background-size: 100% 100%;
    width: 100%;
    min-height: 100vh;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Segoe UI, Arial, Roboto, "PingFang SC", "miui", "Hiragino Sans GB", "Microsoft Yahei", sans-serif;
}

h1, h2, h3, h4, h5, h6, p {
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-enter-from .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

/* 右进左出效果 */
.RgExcess-enter-active,
.RgExcess-leave-active {
    transition: opacity 0.3s ease;
}

.RgExcess-enter,
.RgExcess-leave-to {
    opacity: 0;
}

.RgExcess-leave-active {
    opacity: 0;
}

/* 左进右出效果 */
.LfExcess-enter-active,
.LfExcess-leave-active {
    transition: opacity 0.3s ease;
}

.LfExcess-enter,
.LfExcess-leave-to {
    opacity: 0;
}

.LfExcess-leave-active {
    opacity: 0;
}
</style>
