<template>
  <div style="min-height: 100vh; flex: 1">
    <div id="mineList" class="v-pb-100">
      <div
        class="userinfo-header pt-26 v-px-20"
        @click="navigateTo('accountHistory')"
      >
        <div class="text-white font-bold text-xs">{{ userInfo.Email }}</div>
        <div class="text-[#B7DEFF] v-text-12 v-mt-10">
          {{ userInfo.Uid }}
        </div>
        <div class="v-mt-20 flex justify-between items-center">
          <div class="text-white font-bold text-xs">
            $ {{ hidden ? "*******" : userInfo.Gold }}
          </div>
          <i
            class="bi bi-eye-fill icon-main"
            style="font-size: 1.4rem"
            @click.stop="hideContent"
          ></i>
        </div>
        <div class="text-[#B7DEFF] v-text-12 v-mt-2">
          {{ $t("mine-availableAssets") }}
        </div>
      </div>

      <div style="padding: 0 2.133vw">
        <!-- Deposit & Withdraw -->
        <div
          class="flex justify-between"
          style="padding: 0 2.66vw; margin-top: 2.66vw"
        >
          <div
            class="bg-kline-up v-rounded-base flex items-center mr-2"
            style="width: 42.66667vw; height: 13.866vw"
            @click="navigateTo('deposit')"
          >
            <van-icon
              name="gold-coin-o"
              size="9.5vw"
              class="icon-main text-white v-w-36 v-h-36 v-mx-8"
            />
            <div>
              <div class="text-white v-text-14 font-semibold">
                {{ $t("mine-deposit") }}
              </div>
              <div class="text-white opacity-70 v-text-12">
                {{ $t("mine-billDetails") }}>>
              </div>
            </div>
          </div>
          <div
            class="bg-kline-down v-rounded-base flex items-center ml-2"
            style="width: 42.66667vw; height: 13.866vw"
            @click="navigateTo('withdraw')"
          >
            <van-icon
              name="cash-back-record-o"
              size="9.5vw"
              class="icon-main text-white v-w-36 v-h-36 v-mx-8"
            />
            <div>
              <div class="text-white v-text-14 font-semibold">
                {{ $t("mine-withdraw") }}
              </div>
              <div class="text-white opacity-70 v-text-12">
                {{ $t("mine-billDetails") }}>>
              </div>
            </div>
          </div>
        </div>
        <div >
          <!-- 列表 - 上半段 -->
          <!-- 模拟账户 -->
          <div
            v-if="demoAccount"
            class="item-bg rounded-default flex justify-between items-center v-py-6 v-px-12 v-mt-10 base-shadow"
            @click="openResetDialog"
          >
            <div class="flex items-center">
              <div
                class="v-p-6 icon-bg v-rounded-10 flex flex-col justify-center items-center"
              >
                <i class="icofont-undo" style="font-size: 1.4rem"></i>
              </div>
              <span class="v-p-10 k-text font-semibold v-text-14">{{
                $t("mine-demo_return_simulated_account")
              }}</span>
            </div>
            <el-icon  style="font-size: 1.5rem">
              <ArrowRight />
            </el-icon>
          </div>

          <!-- Wallet -->
          <div
            class="item-bg rounded-default flex justify-between items-center v-py-6 v-px-12 v-mt-10 base-shadow"
            @click="navigateTo('wallet')"
          >
            <div class="flex items-center">
              <div
                class="v-p-6 icon-bg v-rounded-10 flex flex-col justify-center items-center"
              >
                <van-icon name="card" style="font-size: 1.4rem" />
              </div>
              <span class="v-p-10 k-text font-semibold v-text-14">{{
                $t("mine-wallet")
              }}</span>
            </div>
            <el-icon  style="font-size: 1.5rem">
              <ArrowRight />
            </el-icon>
          </div>

          <!-- Real Name Verification -->
          <div
            class="item-bg rounded-default flex justify-between items-center v-py-6 v-px-12 v-mt-10 base-shadow"
            @click="navigateTo('verifyName')"
          >
            <div class="flex items-center">
              <div
                class="v-px-6 icon-bg v-rounded-10 flex flex-col justify-center items-center"
              >
                <i
                  class="bi bi-person-fill-check"
                  style="font-size: 1.4rem"
                ></i>
              </div>
              <span class="v-p-10 k-text font-semibold v-text-14">{{
                $t("mine-verifyName")
              }}</span>
            </div>
            <el-icon  style="font-size: 1.5rem">
              <ArrowRight />
            </el-icon>
          </div>

          <!-- Invite Friends -->
          <div
            class="item-bg rounded-default flex justify-between items-center v-py-6 v-px-12 v-mt-10 base-shadow"
            @click="navigateTo('inviteFriend')"
          >
            <div class="flex items-center">
              <div
                class="v-px-6 icon-bg v-rounded-10 flex flex-col justify-center items-center"
              >
                <i class="bi bi-person-fill-add" style="font-size: 1.4rem"></i>
              </div>
              <span class="v-p-10 k-text font-semibold v-text-14">{{
                $t("mine-inviteFriend")
              }}</span>
            </div>
            <el-icon  style="font-size: 1.5rem">
              <ArrowRight />
            </el-icon>
          </div>

          <!-- Change Password -->
          <div
            class="item-bg rounded-default flex justify-between items-center v-py-6 v-px-12 v-mt-10 base-shadow"
            @click="navigateTo('changePassword')"
          >
            <div class="flex items-center">
              <div
                class="icon-bg v-p-6 v-rounded-10 flex flex-col justify-center items-center"
              >
                <i class="icofont-lock" style="font-size: 1.4rem"></i>
              </div>
              <span class="v-p-10 k-text font-semibold v-text-14">{{
                $t("mine-changePassword")
              }}</span>
            </div>
            <el-icon  style="font-size: 1.5rem">
              <ArrowRight />
            </el-icon>
          </div>

          <!-- Online Service -->
          <div
            class="item-bg rounded-default flex justify-between items-center v-py-6 v-px-12 v-mt-10 base-shadow"
            @click="navigateTo('onlineService')"
          >
            <div class="flex items-center">
              <div
                class="v-p-6 icon-bg v-rounded-10 flex flex-col justify-center items-center"
              >
                <van-icon name="chat" style="font-size: 1.4rem" />
              </div>
              <span class="v-p-10 k-text font-semibold v-text-14">{{
                $t("mine-onlineSerive")
              }}</span>
            </div>
            <el-icon  style="font-size: 1.5rem">
              <ArrowRight />
            </el-icon>
          </div>

          <!-- Complaint email -->
          <div
            class="item-bg rounded-default flex justify-between items-center v-py-6 v-px-12 v-mt-10 base-shadow"
            @click="navigateTo('complaintEmail')"
          >
            <div class="flex items-center">
              <div
                class="v-p-6 icon-bg v-rounded-10 flex flex-col justify-center items-center"
              >
                <van-icon name="smile-comment" style="font-size: 1.4rem" />
              </div>
              <span class="v-p-10 k-text font-semibold v-text-14">{{
                $t("mine-complaintEmail")
              }}</span>
            </div>
            <el-icon  style="font-size: 1.5rem">
              <ArrowRight />
            </el-icon>
          </div>

          <!-- Announcement -->
          <div
            class="item-bg rounded-default flex justify-between items-center v-py-6 v-px-12 v-mt-10 base-shadow"
            @click="navigateTo('announcement')"
          >
            <div class="flex items-center">
              <div
                class="v-p-6 icon-bg v-rounded-10 flex flex-col justify-center items-center"
              >
                <van-icon name="volume" style="font-size: 1.4rem" />
              </div>
              <span class="v-p-10 k-text font-semibold v-text-14">{{
                $t("mine-announcement")
              }}</span>
            </div>
            <el-icon  style="font-size: 1.5rem">
              <ArrowRight />
            </el-icon>
          </div>

          <div class="text-title v-text-14 font-semibold v-my-10">
            {{ $t("mine-establishment") }}
          </div>

          <!-- Language -->
          <div
            class="item-bg rounded-default flex justify-between items-center v-py-6 v-px-12 v-mt-10 base-shadow"
            @click="navigateTo('language')"
          >
            <div class="flex items-center">
              <div
                class="v-p-6 icon-bg v-rounded-10 flex flex-col justify-center items-center"
              >
                <i class="icofont-earth" style="font-size: 1.4rem"></i>
              </div>
              <span class="v-p-10 k-text font-semibold v-text-14">{{
                $t("app-title-language")
              }}</span>
            </div>
            <el-icon  style="font-size: 1.5rem">
              <ArrowRight />
            </el-icon>
          </div>

          <!-- Other -->
          <div
            class="item-bg rounded-default flex justify-between items-center v-py-6 v-px-12 v-mt-10 base-shadow"
            @click="navigateTo('other')"
          >
            <div class="flex items-center">
              <div
                class="v-p-6 icon-bg v-rounded-10 flex flex-col justify-center items-center"
              >
                <van-icon name="setting" style="font-size: 1.4rem" />
              </div>
              <span class="v-p-10 k-text font-semibold v-text-14">{{
                $t("mine-other")
              }}</span>
            </div>
            <el-icon  style="font-size: 1.5rem">
              <ArrowRight />
            </el-icon>
          </div>
        </div>
      </div>
    </div>
    <tabBar />
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import tabBar from "@/pages/components/common/tab-bar.vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { showToast, showLoadingToast, showConfirmDialog } from "/node_modules/vant";

const { t } = useI18n();
const router = useRouter();
const store = useStore();
const userInfo = computed(() => store.getters["userInfo"]);
const demoAccount = computed(() => store.getters.userInfo.IsDemo == 1);
const hidden = ref(false);

const hideContent = () => {
  hidden.value = !hidden.value;
};

const routes = {
  accountHistory: "/pages/components/mine/accountHistory",
  deposit: "/pages/components/deposit/depositIndex",
  withdraw: "/pages/components/withdraw/withdrawIndex",
  wallet: "/pages/components/mine/wallet/walletIndex",
  verifyName: "/pages/components/mine/verifyName/verifyName",
  inviteFriend: "/pages/components/mine/inviteFriend",
  changePassword: "/pages/components/mine/changePassword",
  onlineService: "/pages/components/mine/onlineService",
  complaintEmail: "/pages/components/mine/complaintEmail",
  announcement: "/pages/components/mine/announcementIndex",
  language: "/pages/components/mine/languageSelect",
  other: "/pages/components/mine/otherIndex",
};

const navigateTo = (path) => {
  if (demoAccount.value) {
    if (
      [
        "deposit",
        "withdraw",
        "wallet",
        "verifyName",
        "inviteFriend",
        "changePassword",
      ].includes(path)
    ) {
      showToast({
        message: t("systemHint-pls_register_login_real_account"),
        icon: "fail",
      });
    } else {
      const targetPath = routes[path];
      if (targetPath) {
        router.push({ path: targetPath });
      }
    }
  } else {
    const targetPath = routes[path];
    if (targetPath) {
      router.push({ path: targetPath });
    }
  }
};

const openResetDialog = () => {
  const originalGold = userInfo.value.Gold;
  showConfirmDialog({
    title: t("dialog-title"),
    message: t("popupHint-whether_return_it"),
    confirmButtonText: t("public-text-confirm"),
    cancelButtonText: t("public-text-cancel"),
  })
    .then(() => {
      store.commit("RESET_USER_GOLD", originalGold);
      showLoadingToast({
        message: t("systemHint-reset_successful"),
        icon: "success",
        duration: 1000,
        forbidClick: true,
      });
    })
    .catch(() => {
      // Handle the cancel action
    });
};
</script>

<style scoped>
.userinfo-header {
  /* background: linear-gradient(90deg, #207aff, #015feb); */
  width: 100%;
  height: 42.6vw;
}
</style>
