<template>
  <div style="min-height: 100vh; flex: 1">
    <topNavBar
      :leftIcon="true"
      :headerTitle="$t('wallet-bind-currency-address')"
      :iconName="'Plus'"
      :handleClick="bindBank"
    />

    <div class="list-main">
      <div class="" v-for="(item, index) in bankCurrencyList" :key="index">
        <bankCurrencyItem :items="item" @delete-currency="deleteCurrency" />
      </div>
      <div class="v-h-full" v-if="bankCurrencyList.length == 0">
        <noRecord />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { store } from "@/assets/store/data.js";
import topNavBar from "@/pages/components/common/topNavBar.vue";
import bankCurrencyItem from "@/pages/components/mine/wallet/bindPages/bankCurrencyItem.vue";
import noRecord from "@/pages/components/common/noRecord.vue";

const { t } = useI18n();
const router = useRouter();
const bankCurrencyList = store.value.bankCurrencyList;

const bindBank = () => {
  router.push({ name: "WithdrawType", query: { type: "2" } });
};

const deleteCurrency = (id) => {
  const index = bankCurrencyList.findIndex((item) => item.id === id);
  if (index !== -1) {
    bankCurrencyList.splice(index, 1);
  }
};
</script>

<style scoped></style>
