import { dispose, init } from 'klinecharts'
import { useRouter } from 'vue-router';
import {ref} from "vue";

const router = useRouter();
let chart = '';
// 测试k线数据
function getData (timestamp = new Date().getTime(), length = 800) {
  let basePrice = 5000
  timestamp = Math.floor(timestamp / 1000 / 60) * 60 * 1000 - length * 60 * 1000
  const dataList = []
  for (let i = 0; i < length; i++) {
    const prices = []
    for (let j = 0; j < 4; j++) {
      prices.push(basePrice + Math.random() * 60 - 30)
    }
    prices.sort()
    const open = +(prices[Math.round(Math.random() * 3)].toFixed(2)) //开盘
    const high = +(prices[3].toFixed(2))
    const low = +(prices[0].toFixed(2))
    const close = +(prices[Math.round(Math.random() * 3)].toFixed(2)) //收盘
    const volume = Math.round(Math.random() * 100) + 10
    const turnover = (open + high + low + close) / 4 * volume
    dataList.push({ timestamp, open, high, low, close, volume, turnover })

    basePrice = close
    timestamp += 60 * 1000
  }
  return dataList
}

// 创建折线图数据
//status: 0=open 1=休盘
//state: 0=+ 1=-
const lineData = [
  { "keyName": "Forex",'open':0.5, 'close':0.6, 'Pct':"0.15%",state:"0", status:"0", eachLots:"100", margin:"10", openLoss:"2.09217", openProfit:"2.09237", "Data": [
      { timestamp: 1609459200000, open: 1, close: 10 },
      { timestamp: 1709545600000, open: 4, close: 11 },
      { timestamp: 1809632000000, open: 55, close: 22 },
      { timestamp: 1909718400000, open: 6, close: 13 },
      { timestamp: 2009804800000, open: 7, close: 14 },
      { timestamp: 2109804800000, open: 7, close: 14 },
      { timestamp: 2209804800000, open: 1, close: 14 },
      { timestamp: 2309804800000, open: 9, close: 14 },
      { timestamp: 2409804800000, open: 1, close: 14 },
    ]},
  { "keyName": "Crypto", 'open':0.6, 'close':0.6, 'Pct':"0.16%", state:"1",status:"0", eachLots:"1000", margin:"10", 'openLoss':"8.09217", 'openProfit':"8.13237", "Data": [
      { timestamp: 1609459200000, open: 100, close: 10 },
      { timestamp: 1709545600000, open: 20, close: 11 },
      { timestamp: 1809632000000, open: 30, close: 22 },
      { timestamp: 1909718400000, open: 40, close: 13 },
      { timestamp: 2009804800000, open: 50, close: 14 },
      { timestamp: 2109804800000, open: 60, close: 14 },
      { timestamp: 2209804800000, open: 70, close: 14 },
      { timestamp: 2309804800000, open: 80, close: 14 },
      { timestamp: 2409804800000, open: 18, close: 14 },
    ]},
  { "keyName": "Metal",'open':0.7,'close':0.6, 'Pct':"0.17%",state:"0",status:"1", eachLots:"10", margin:"1000", openLoss:"67.09217", openProfit:"67.00237", "Data": [
      { timestamp: 1609459200000, open: 1, close: 10 },
      { timestamp: 1709545600000, open: 1, close: 11 },
      { timestamp: 1809632000000, open: 1, close: 22 },
      { timestamp: 1909718400000, open: 1, close: 13 },
      { timestamp: 2009804800000, open: 1, close: 14 },
      { timestamp: 2109804800000, open: 1, close: 14 },
      { timestamp: 2209804800000, open: 1, close: 14 },
      { timestamp: 2309804800000, open: 1, close: 14 },
      { timestamp: 2409804800000, open: 1, close: 14 },
    ]},
  { "keyName": "Oil",'open':0.8,'close':0.6,'Pct':"0.18%",state:"1",status:"0", eachLots:"1000", margin:"100", openLoss:"23.09217", openProfit:"23.09237", "Data": [
      { timestamp: 1609459200000, open: 1, close: 10 },
      { timestamp: 1709545600000, open: 1, close: 11 },
      { timestamp: 1809632000000, open: 1, close: 22 },
      { timestamp: 1909718400000, open: 1, close: 13 },
      { timestamp: 2009804800000, open: 1, close: 14 },
      { timestamp: 2109804800000, open: 1, close: 14 },
      { timestamp: 2209804800000, open: 1, close: 14 },
      { timestamp: 2309804800000, open: 1, close: 14 },
      { timestamp: 2409804800000, open: 1, close: 14 },
    ]},
  { "keyName": "CFD",'open':0.9,'close':0.6,'Pct':"0.19%",state:"0",status:"0", eachLots:"100", margin:"10", openLoss:"33.09217", openProfit:"34.09237", "Data": [
      { timestamp: 1609459200000, open: 1, close: 10 },
      { timestamp: 1709545600000, open: 1, close: 11 },
      { timestamp: 1809632000000, open: 1, close: 22 },
      { timestamp: 1909718400000, open: 1, close: 13 },
      { timestamp: 2009804800000, open: 1, close: 14 },
      { timestamp: 2109804800000, open: 1, close: 14 },
      { timestamp: 2209804800000, open: 1, close: 14 },
      { timestamp: 2309804800000, open: 1, close: 14 },
      { timestamp: 2409804800000, open: 1, close: 14 },
    ]},
];

var timeoutId;
//获取面积图数据
function getAreaData(_type){
    let arr = [];
    
    let ChartIndex = 1;


    // 初始化面积图
    let domArr = document.querySelectorAll('.klChart');
    domArr.forEach((item) => {
        const child = item.children;
        for(let i = 1; i < child.length; i++){
            child[i].remove();
        }
    })

    // 初始化加载状态
    let loadingArr = document.querySelectorAll('.van-loading');
    for (let i = 0; i < loadingArr.length; i++) {
        loadingArr[i].style.display = '';
    }

    // 清除前一次的延迟执行
    if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
    }

    //绘画所以面积图
    if(!_type){
        timeoutId = setTimeout(() => {
            lineData.forEach((item, index) => {
                ViveAreaLine('k-line-chart'+(index+1),item)
            });
            timeoutId = null;
        }, 1000);

        return lineData;
    }else{
        // 根据类型绘画面积图
        lineData.forEach((item, index) => {
            if(item.keyName == _type){
                timeoutId = setTimeout(() => {
                    ViveAreaLine('k-line-chart'+ChartIndex,item)
                    ChartIndex++;
                    timeoutId = null;
                },1000)
                arr.push(item);
            }
        });
        
        return arr;
    }
}

// 绘画面积图
function ViveAreaLine(_class,_data){
    let parent = document.getElementById(_class);
    if (parent) {
        let children = parent.getElementsByClassName('van-loading');
        for (let i = 0; i < children.length; i++) {
            let child = children[i];
            if (child.parentNode === parent) { // 确保是直接子元素
                child.style.display = 'none';
            }
        }
    }
    
    try{
        chart = init(_class);
       
        chart.setStyles({
                grid: {
                show: false
            },
            xAxis: {
                show: false
            },
            yAxis: {
                show: false,
                crosshair:{
                    show:false,
                    horizontal:{
                        show:false,
                        line:{
                            show:false
                        }
                    }
                }
            },
            crosshair: {
                show: false,
            },
            technicalIndicator: {
                line: {
                    show: true,
                    line: { color: 'blue' }
                },
                tooltip:{
                    showRule:'none',
                }
            },
            candle: {
                type: 'area',
                tooltip: {
                    showRule: 'none' // 隐藏提示信息
                },
                // 面积图
                area: {
                    lineSize: 1,
                    lineColor: _data.state == 0 ? '#2196F3' : 'red',
                    value: 'close',
                    smooth: true,
                    // 33, 150, 243
                    backgroundColor: [{
                        offset: 0,
                        color: _data.state == 0 ? 'rgba(33, 150, 243, 0.01)' :'rgba(255, 39, 39, 0.01)'
                    }, {
                        offset: 1,
                        color: _data.state == 0 ? 'rgba(33, 150, 243, 0.01)' :'rgba(255, 39, 39, 0.2)'
                    }],
                    point: {
                        show: false,
                    },
                    style:{
                        line:{
                            style:'none'
                        }
                    }
                },
                priceMark:{
                    show:false
                }
            },
            tooltip: {
                showRule: 'none' // 隐藏提示信息
            },
            shape:{
                line:{
                    style: 'solid',
                    color: '#2196F3',
                    size: 1,
                    dashValue: [2, 2]
                }
            },
        })

        chart.applyNewData(_data.Data.map(d => ({
            close: d.close,
            timestamp: d.timestamp
        })));

        // 确保图表显示所有数据点
        chart.scrollByDistance(100,300);
    }catch{
        console.log('数据未加载成功')
    }
}   

//获取k线图数据
function getKData(_type){
    return !_type ? lineData.find(item => item.keyName == 'Forex') : lineData.find(item => item.keyName == _type);
}

// 绘画k线图
function ViveKLine(_class,_data){
    try{
        chart = init(_class);
        chart.applyNewData(getData())
        chart.loadMore((timestamp) => {
            loadMoreTimer = setTimeout(() => {
                chart.applyMoreData(getData(timestamp), true)
            }, 2000)
        })
        chart.applyNewData(getData(), true)
        chart.setStyles({
            candle:{
                bar:{
                    upColor:'#0166fc',
                    upBorderColor:'#0166fc',
                    upWickColor:'#0166fc'
                },
                tooltip:{
                    custom:[
                        { title: 'High', value: '{high}' },
                        { title: 'Open', value: '{open}' },
                        { title: 'Low', value: '{low}' },
                    ]
                },
            },
        })
        updateData()
        chart.scrollByDistance(0,1);        
    }catch(e){
        console.log(e)
    }
}

// 更新k线数据
function updateData () {
    setTimeout(() => {
        try{
            const dataList = chart.getDataList()
            const lastData = dataList[dataList.length - 1]
            const newData = { ...lastData }
            newData.close += (Math.random() * 20 - 10)
            newData.high = Math.max(newData.high, newData.close)
            newData.low = Math.min(newData.low, newData.close)
            newData.volume += Math.round(Math.random() * 40)
            chart.updateData(newData)
            updateData()
        }catch(e){
            console.log(e)
        }
    }, 1000)
}

// 更新数据，相同则覆盖，不同则追加
function applyNewData() {
    setTimeout(() => {
        try{
            chart.applyNewData(getData(), true)
        }catch(e){
            console.log(e)
        }
    }, 1000)
}

// 跳转
function SkipPage(KeyName){
    this.$router.push({
        name:'TradeIndex',
        params:{
            'KeyName':KeyName
        }
    })
}

export{
    ViveAreaLine,getAreaData,getKData,ViveKLine,getData,updateData, applyNewData
}

export default{
    install(app){
        app.config.globalProperties.$getAreaData=getAreaData;
        app.config.globalProperties.$ViveAreaLine=ViveAreaLine;
        app.config.globalProperties.$lineData=lineData;
        app.config.globalProperties.$SkipPage=SkipPage;
        app.config.globalProperties.$getKData=getKData;
        app.config.globalProperties.$ViveKLine=ViveKLine;
    },
}