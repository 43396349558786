<template>
  <div class="bg-main v-m-10 v-p-10 v-mt-30">
    <div class="k-text v-mx-10 v-text-14 font-bold">{{ $t("deposit-record") }}</div>
      <van-list>
        <van-cell
          class="bg-general v-text-12 v-rounded-8 base-shadow v-mt-10 v-p-10"
          v-for="(item, index) in filteredDepositRecords"
          :key="index"
        >
          <div class="flex justify-between">
            <div class="flex-1 text-left">
              <div class="text-color-7">
                {{ $t("deposit-recharge_amount") }}
              </div>
              <div class="k-text">{{ item.rechargeAmount }}</div>
            </div>
            <div class="flex-1 text-center">
              <div class="text-color-7">
                {{ $t("deposit-recharge_type") }}
              </div>
              <div class="k-text">{{ item.type }}</div>
            </div>
            <div class="flex-1 text-right">
              <div class="text-color-7">
                {{ $t("deposit-recharge_unit") }}
              </div>
              <div class="k-text">{{ item.unit }}</div>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="flex-1 text-left">
              <div class="text-color-7">
                {{ $t("deposit-recharge_status") }}
              </div>
              <div class="k-text">{{ item.status }}</div>
            </div>
            <div class="flex-1 text-right">
              <div class="text-color-7">{{ $t("public-text-date") }}</div>
              <div class="k-text">{{ item.date }}</div>
            </div>
          </div>
        </van-cell>
      </van-list>
  </div>
</template>

<script setup>
import { reactive, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { store } from "@/assets/store/data.js";

const props = defineProps({
  curType: {
    type: String,
    required: true,
  },
});

const { t } = useI18n();
const router = useRouter();
const depositRecords = store.value.depositRecords;
const loading = ref(false);
const finished = ref(false);
const refreshing = ref(false);

const onLoad = () => {
  loading.value = true;
  depositRecords;
  // setTimeout(() => {
  //     const newRecords = loadMoreDepositRecords();  // 获取新数据
  //     // 过滤掉已有的数据
  //     const filteredRecords = newRecords.filter(newRecord => {
  //       return !depositRecords.some(oldRecord => oldRecord.id === newRecord.id);
  //     });
  //     depositRecords = [...depositRecords, ...filteredRecords];  // 将新数据追加到 depositRecords
  //     loading.value = false;
  //     // 检查是否加载完成，这里根据具体情况设置 finished 的值
  //     // 如果没有更多数据了，设置 finished.value 为 true
  //     // finished.value = true;
  //   }, 1000);
  loading.value = false;
};

const onRefresh = () => {
  // 清空列表数据
  finished.value = false;

  // 重新加载数据
  // 将 loading 设置为 true，表示处于加载状态
  loading.value = true;
  onLoad();
};

// function refreshDepositRecords() {
//       // 模拟刷新数据的方法，实际情况根据你的需求来实现
//       // 返回一个新的充值记录数组
//       return [
//         // 新的充值记录数据
//       ];
//     }

//     function loadMoreDepositRecords() {
//       // 模拟加载更多数据的方法，实际情况根据你的需求来实现
//       // 返回一个新的充值记录数组
//       return [
//         // 新的充值记录数据
//       ];
//     }

//根据type传递数据
const filteredDepositRecords = computed(() => {
  return depositRecords.filter((record) => record.type === props.curType);
});
</script>

<style scoped>
</style>
