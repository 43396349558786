import { createStore } from 'vuex'
import getters from './getters.js'

// modules
import user from './user/user.js'
import theme from './theme/theme.js'

const store = createStore({
    modules: {
        user,
        theme,
    },
    getters
})

export default store


