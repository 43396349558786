<template>
  <div class="v-mt-10 v-mx-10 base-shadow v-text-12 coin-list bg-general v-rounded-8">
    <van-swipe-cell :style="showDel ? showBtnStyle : hideBtnStyle">
      <div class="bg-general v-p-20 v-rounded-8">
        <div>
          <span class="pr-3 k-text">{{ $t("bind-bank-name") }}:</span>
          <span class="k-text">{{ items.bankName }}</span>
        </div>
        <div class="v-mt-6">
          <span class="pr-3 k-text">{{ $t("bind-bank-address") }}:</span>
          <span class="k-text">{{ items.bankAddress }}</span>
        </div>
        <div class="v-mt-6">
          <span class="pr-3 k-text">{{ $t("bind-bank-swift") }}:</span>
          <span class="k-text">{{ items.bankSwift }}</span>
        </div>
        <div class="v-mt-6">
          <span class="pr-3 k-text">{{ $t("bind-bank-payee") }}:</span>
          <span class="k-text">{{ items.bankPayee }}</span>
        </div>
        <div class="v-mt-6">
          <span class="pr-3 k-text">{{ $t("bind-bank-payee-account") }}:</span>
          <span class="k-text">{{ items.bankPayeeAcc }}</span>
        </div>
      </div>
      <template #right>
        <div 
          class="flex items-center v-h-full bg-kline-down v-rounded-right"
          @click="comfirmDelete"
        >
          <van-button square class="bg-kline-down v-border-0">
            <span class="text-white v-text-12">{{ $t("public-btn-delete") }}</span>
          </van-button>
        </div>
      </template>
    </van-swipe-cell>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  items: {
    type: Object,
    default: () => {},
  },
});
const showDel = ref(false);
const showBtnStyle = ref(
  "transform: translate3d(-75.2031px, 0px, 0px); transition-duration: 0.6s;"
);
const hideBtnStyle = ref(
  "transform: translate3d(0px, 0px, 0px); transition-duration: 0.6s;"
);

const comfirmDelete = () => {
  console.log('22')
}
</script>

<style scoped>
:deep(.van-button--default){
  /* border: 0px #f23c48 solid; */
}
:deep(.van-swipe-cell__right) {
  transform: translate3d(101%, 0, 0);
}
</style>
