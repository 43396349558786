import ZHTWLang from '@/assets/language/zh' // 中文默认文件
import ENLang from '@/assets/language/en' // 英文
import { createI18n } from 'vue-i18n'

const cur_lang = localStorage.getItem('Lang') ?? 'en';
// const cur_lang = localStorage.getItem('Lang') ? localStorage.getItem('Lang') : 'en';

const i18n = createI18n({
    legacy: false, // 使用组合式API
    locale: cur_lang,
    messages:{
        'en': ENLang,
        'zh-TW': ZHTWLang
    }
})

export default i18n;