<template>
  <div style="min-height: 100vh; flex: 1">
    <div class="v-pb-100">
      <topNavBar :leftIcon="false" :headerTitle="$t('tabbar-news')" />

      <div class="v-px-12">
        <div class="flex justify-center v-py-16" v-if="loading">
          <van-loading type="circular" size="24px">{{
            $t("public-text-loading")
          }}</van-loading>
        </div>

        <div v-else>
            <van-pull-refresh 
                v-model="loadingRefresh"
                :head-height="80"
                @refresh="onRefresh"
                :pulling-text="$t('public-text-scroll_down_refresh')"
                :loosing-text="$t('public-text-loose-to-refresh')"
                :loading-text="$t('public-text-loading')"
            >
                <div v-if="newsList.length == 0">
                <noRecord />
                </div>

                <div id="list" v-else>
                <div
                    v-for="(item, index) in newsList"
                    :key="index"
                    class="list-main v-rounded-base base-shadow v-mt-10 v-p-10 flex justify-between v-h-100"
                    @click="openItem(item)"
                >
                    <div class="flex flex-col justify-between v-w-172">
                    <div class="v-text-12 font-bold title truncate">
                        {{ item.title }}
                    </div>
                    <div class="flex-1 content k-text">{{ item.desc }}</div>
                    <div class="date v-text-12">{{ item.time }}</div>
                    </div>
                    <img
                    :src="getImageUrl(item.image)"
                    class="van-image v-w-130 v-rounded-4 overflow-hidden"
                    />
                </div>
                </div>
          </van-pull-refresh>
        </div>
      </div>
    </div>
    <tabBar />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { store } from "@/assets/store/data.js";
import topNavBar from "@/pages/components/common/topNavBar.vue";
import tabBar from "@/pages/components/common/tab-bar.vue";
import noRecord from "@/pages/components/common/noRecord.vue";

const router = useRouter();
const newsList = ref([]);
const loading = ref(true);
const loadingRefresh  = ref(false);

const onRefresh = () => {
  setTimeout(() => {
    fetchNews()
    loadingRefresh.value = false;
  }, 1000);
};

const fetchNews = async () => {
  try {
    await new Promise((resolve) => setTimeout(resolve, 600));
    newsList.value = store.value.newsList;
  } catch (error) {
    console.error("Failed to fetch news:", error);
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  fetchNews();
});

const getImageUrl = (imageName) => {
  let url = require(`@/assets/image/${imageName}`);
  if (imageName) return url;
};

const openItem = (item) => {
  router.push({
    name: "NewsDetails",
    query: { id: item.id },
  });
};
</script>

<style scoped>
.van-image {
  position: relative;
  display: inline-block;
  align-content: center;
}

.content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  line-height: 5.33333vw;
  max-height: 10.66667vw;
}
</style>
