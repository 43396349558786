<template>
  <div style="min-height: 100vh; flex: 1;">
    <topNavBar :leftIcon="true" :headerTitle="$t('mine-wallet')" />

    <div class="v-p-20">
      <div class="font-bold k-text">{{ $t('public-text-balance') }}</div>
      <div class="font-extrabold text-kline-up v-text-16">{{ userInfo.Gold }}</div>
    </div>

    <div 
      class="bg-general v-rounded-base v-mx-10 flex items-center justify-between v-mt-20 v-p-10 base-shadow"
      @click="navigateTo('bindCard')"
      >
      <span class="v-text-12 k-text">{{ $t('wallet-bind-bank')}}</span>
      <i class="icofont-simple-right icon-right" style="font-size: 1.5rem;"></i>  
    </div>

    <div 
      class="bg-general v-rounded-base v-mx-10 flex items-center justify-between v-mt-20 v-p-10 base-shadow"
      @click="navigateTo('bindCurrency')"
    >
      <span class="v-text-12 k-text">{{ $t('wallet-bind-currency-address')}}</span>
      <i class="icofont-simple-right icon-right" style="font-size: 1.5rem;"></i>  
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import topNavBar from "@/pages/components/common/topNavBar.vue";

const store = useStore();
const router = useRouter();
const userInfo = computed(() => store.getters['userInfo']);

const navigateTo = (path) => {
  if (path == 'bindCard') {
    router.push({ name: 'BindBank' })
  } else if (path == 'bindCurrency') {
    router.push({ name: 'BindCurrency'})
  }
}
</script>

<style scoped></style>
