<template>
  <div id="deposit">
    <van-form @submit="onSubmit">
      <van-cell-group class="bg-main">
        <div class="bg-general customBottomLine v-mx-10">
          <van-field
            readonly
            clickable
            v-model="currencyLabel"
            :label="$t('deposit-receipt_currency')"
            :value="currencyLabel"
            :placeholder="currencyLabel"
            class="bg-general k-text"
            right-icon="arrow"
            style="
              height: fit-content;
              font-size: 3.73333vw;
              line-height: 4.26667vw;
            "
            @click="showCurrencyPicker = true"
          />
          <!-- Currency Picker -->
          <van-popup v-model:show="showCurrencyPicker" position="bottom" round>
            <van-picker
              show-toolbar
              :columns="currencyList"
              :cancel-button-text="$t('public-text-cancel')"
              :confirm-button-text="$t('public-text-confirm')"
              @cancel="showCurrencyPicker = false"
              @confirm="onConfirm"
            />
          </van-popup>
        </div>

        <!-- 默认充值信息 -->
        <van-radio-group v-model="checked" class="bg-general v-pt-10 pb-3 customBottomLine custom-x-shadow v-mx-10">
          <van-cell-group inset class="bg-general">
            <div class="k-text v-mt-10">{{ $t("bind-bank-name") }}:</div>
            <van-field
              placeholder="Swissquote Bank Ltd"
              class="hasVal"
              style="
                height: 11vw;
                border-radius: 5vw;
                align-items: center;
                font-size: 3.73333vw;
              "
              disabled
            />

            <div class="k-text v-mt-10">{{ $t("bind-bank-address") }}:</div>
            <van-field
              placeholder="Chemin de la Cretaux 33, 1196 GLAND, SWITZERLAND"
              class="hasVal"
              style="
                height: 11vw;
                border-radius: 5vw;
                align-items: center;
                font-size: 3.73333vw;
              "
              disabled
            />

            <div class="k-text v-mt-10">{{ $t("bind-bank-swift") }}:</div>
            <van-field
              placeholder="SWQBCHZZXXX"
              class="hasVal"
              style="
                height: 11vw;
                border-radius: 5vw;
                align-items: center;
                font-size: 3.73333vw;
              "
              disabled
            />

            <div class="k-text v-mt-10">{{ $t("bind-bank-payee") }}:</div>
            <van-field
              placeholder="Maimaitijiang KERANMU"
              class="hasVal"
              style="
                height: 11vw;
                border-radius: 5vw;
                align-items: center;
                font-size: 3.73333vw;
              "
              disabled
            />

            <div class="k-text v-mt-10">{{ $t("bind-bank-payee-account") }}:</div>
            <van-field
              placeholder="CH1208781000236881000"
              class="hasVal"
              style="
                height: 11vw;
                border-radius: 5vw;
                align-items: center;
                font-size: 3.73333vw;
              "
              disabled
            />

            <div class="k-text v-mt-10">{{ $t("public-text-remarks") }}:</div>
            <van-field
              placeholder=""
              class="hasVal"
              style="
                height: 11vw;
                border-radius: 5vw;
                align-items: center;
                font-size: 3.73333vw;
              "
              disabled
            />
          </van-cell-group>
          <div
            class="flex justify-end v-mt-10 v-mb-10 mx-3"
            style="font-size: 1.6rem"
          >
            <van-radio name="default-recharge" icon-size="6vw"></van-radio>
          </div>
        </van-radio-group>

        <!-- 自定义填写充值信息 -->
        <van-radio-group v-model="checked" class="bg-general v-pt-10 pb-3 v-mx-10 customBottomLine v-rounded-bottom custom-bottom-shadow">
          <van-cell-group inset class="bg-general">
            <div class="k-text v-mt-10">{{ $t("bind-bank-name") }}:</div>
            <van-field
              placeholder=""
              class="hasVal"
              style="
                height: 11vw;
                border-radius: 5vw;
                align-items: center;
                font-size: 3.73333vw;
              "
              disabled
            />

            <div class="k-text v-mt-10">{{ $t("bind-bank-address") }}:</div>
            <van-field
            placeholder=""
            class="hasVal"
              style="
                height: 11vw;
                border-radius: 5vw;
                align-items: center;
                font-size: 3.73333vw;
              "
              disabled
            />

            <div class="k-text v-mt-10">{{ $t("bind-bank-swift") }}:</div>
            <van-field
              placeholder=""
              class="hasVal"
              style="
                height: 11vw;
                border-radius: 5vw;
                align-items: center;
                font-size: 3.73333vw;
              "
              disabled
            />

            <div class="k-text v-mt-10">{{ $t("bind-bank-payee") }}:</div>
            <van-field
              placeholder=""
              class="hasVal"
              style="
                height: 11vw;
                border-radius: 5vw;
                align-items: center;
                font-size: 3.73333vw;
              "
              disabled
            />

            <div class="k-text v-mt-10">{{ $t("bind-bank-payee-account") }}:</div>
            <van-field
              placeholder=""
              class="hasVal"
              style="
                height: 11vw;
                border-radius: 5vw;
                align-items: center;
                font-size: 3.73333vw;
              "
              disabled
            />

            <div class="k-text v-mt-10">{{ $t("public-text-remarks") }}:</div>
            <van-field
              placeholder=""
              class="hasVal"
              style="
                height: 11vw;
                border-radius: 5vw;
                align-items: center;
                font-size: 3.73333vw;
              "
              disabled
            />
          </van-cell-group>
          <div
            class="flex justify-end v-mt-10 v-mb-10 mx-3"
            style="font-size: 1.6rem"
          >
            <van-radio name="custom-recharge" icon-size="6vw"></van-radio>
          </div>
        </van-radio-group>

        <!-- 上传凭证 -->
        <div class="v-my-10 v-px-20">
          <div class="k-text v-mt-18 v-text-12">
            {{ $t("deposit-recharge_amount") }}
          </div>
          <div
            class="input-bg base-input v-w-full v-mt-10"
          >
            <van-field
              class="input-bg v-mx-10"
              style="
                align-items: center;
                font-size: 3.26667vw;
              "
              v-model="valueRecharge"
              :placeholder="$t('deposit-recharge-placeholder')"
            />
          </div>

          <div class="flex flex-col items-center v-mt-20">
            <van-uploader
              class="mx-auto"
              v-model="certImage"
              :max-count="1"
              :after-read="afterRead"
              @click-preview="onClickPreview"
            ></van-uploader>
            <van-popup position="center">
              <van-image
                :src="previewCertImageSrc"
                style="object-fit: contain"
              />
            </van-popup>
          </div>
          <div class="k-text text-center v-text-12 px-5">
            {{ $t("doposit-upload-cert") }}
          </div>
        </div>

        <div class="flex justify-center v-h-40 mx-auto w-[80%] v-my-10">
          <van-button class="btn-blue v-border-0" size="large" round native-type="submit">{{
            $t("public-btn-submit")
          }}</van-button>
        </div>
      </van-cell-group>
    </van-form>
  </div>

  <!-- 历史记录列表 -->
  <DepositRecord :curType="$t('deposit-bank-card')" />
</template>

<script setup>
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { store } from "@/assets/store/data.js";
import { showToast } from "/node_modules/vant";
import DepositRecord from "@/pages/components/deposit/components/depositRecord.vue";

const { t } = useI18n();
const router = useRouter();
const currencyList = ref([{ text: "USD", value: "USD" }]);
const currencyLabel = ref(currencyList.value[0].text);
const showCurrencyPicker = ref(false);
const valueRecharge = ref("");
const checked = ref("default-recharge");
const certImage = ref([]);
const previewCertImageSrc = ref("");

const onConfirm = (val) => {
  currencyLabel.value = val.selectedOptions[0].text;
  showCurrencyPicker.value = false;
};

const afterRead = (file) => {
  certImage.value = [file];
};

const onClickPreview = (file) => {
  previewCertImageSrc.value = file.content || file.url;
};

const onSubmit = () => {
  if (!currencyLabel.value || !valueRecharge.value || !certImage.value) {
    showToast({
      message: t("systemHint-missing_params-or-val-error"),
      icon: "fail",
    });
  } else {
    showToast({
      message: t("systemHint-operation_successful"),
      icon: "success",
    });
    clearForm();
  }
};

const clearForm = () => {
  currencyLabel.value = "";
  valueRecharge.value = "";
  certImage.value = [];
};
</script>

<style scoped>
.base-input {
  border-radius: 5.33333vw;
  background: #f8f8f8;
  padding-top: 2.13333vw;
  padding-bottom: 2.13333vw;
  height: 10.66667vw;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
</style>
