<template>
  <div v-if="loading">
    <van-skeleton class="mt-5" title :row="3" />
    <van-skeleton class="v-mt-10" title :row="3" />
  </div>
  <van-list
    id="vanList"
    v-else-if="holdingDataList.length > 0"
  >
    <van-cell
      v-for="(item, index) in holdingDataList"
      :key="index"
      class="customList"
    >
      <div
        class="list-item v-rounded-10 v-mt-8 v-mb-8 v-p-16 base-shadow"
        @click="openOrderPopup(item)"
      >
        <div class="flex justify-between items-end">
          <div class="k-text v-text-14">{{ item.code }}</div>
        </div>
        <div class="flex items-center justify-between v-text-12 v-mt-4">
          <div class="k-text">
            <span>{{ item.originPrice }}</span>
            <span class="px-2">-></span>
            <span>{{ item.updatePrice }}</span>
          </div>
          <div
            class="flex trade-tag"
            :class="isSell(item) ? 'sell-tag' : 'buy-tag'"
          >
            <div
              class="v-px-8 text-white"
              :class="isSell(item) ? 'bg-kline-down' : 'bg-kline-up'"
            >
              {{ item.tradeStatus }}
            </div>
            <div
              class="v-px-6"
              :class="isSell(item) ? 'text-kline-down' : 'text-kline-up'"
            >
              {{ item.quantity }} {{ $t('public-text-lots') }}
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between v-mt-2">
          <div class="sub-text v-text-12 text-left">
            <div>
              <span>{{ $t("order-order_id") }}</span>
              <span> #{{ item.orderId }}</span>
            </div>
            <div class="">{{ item.createAt }}</div>
          </div>
          <div
            class="v-text-sm"
            :class="isProfit(item) ? 'text-kline-up' : 'text-kline-down'"
          >
            {{ item.factProfits }}
          </div>
        </div>
      </div>
    </van-cell>
  </van-list>

  <div v-else>
    <noRecord />
  </div>

  <!-- 数据内容弹窗 -->
  <van-popup
    v-model:show="showPopup"
    position="bottom"
    round
    closeable
    :icon-prefix="'van-icon'"
    :close-icon="'cross k-text font-bold bg-icon v-p-6 v-text-14 v-rounded-4'"
    style="z-index: 2030; padding: 3.73333vw"
    :style="`height: ${orderStatus !== 0 ? '40%' : '60%'}`"
  >
    <div class="popup-content">
      <div class="v-text-16 k-text font-bold v-mb-20">
        {{ $t("order-order_details") }}
      </div>
      <div class="flex justify-between items-end">
        <div class="k-text">{{ selectedItem?.code }}</div>
      </div>
      <div class="flex items-center justify-between v-text-12 my-2">
        <div class="k-text">
          <span>{{ selectedItem?.originPrice }}</span>
          <span class="px-2">-></span>
          <span
            :class="isSell(selectedItem) ? 'text-kline-down' : 'text-kline-up'"
            >{{ selectedItem?.updatePrice }}</span
          >
        </div>
        <div
          class="flex trade-tag"
          :class="isSell(selectedItem) ? 'sell-tag' : 'buy-tag'"
        >
          <div
            class="v-px-8 text-white"
            :class="isSell(selectedItem) ? 'bg-kline-down' : 'bg-kline-up'"
          >
            {{ selectedItem?.tradeStatus }}
          </div>
          <div
            class="v-px-6"
            :class="isSell(selectedItem) ? 'text-kline-down' : 'text-kline-up'"
          >
            {{ selectedItem?.quantity }} Lots
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between">
        <div class="text-[#cdcdcd] v-text-12 text-left">
          <div>
            <span>{{ $t("order-margin") }}:</span>
            <span>{{ selectedItem?.margin }}</span>
          </div>
          <div>
            <span>{{ $t("withdraw-handling_fee") }}:</span>
            <span>{{ selectedItem?.handlingFee }}</span>
          </div>
          <div>
            <span>{{ $t("order-set_loss") }}:</span>
            <span>{{ selectedItem?.targetLossPrice }}</span>
          </div>
          <div>
            <span>{{ $t("order-take_profit") }}:</span>
            <span>{{ selectedItem?.targetProfitPrice }}</span>
          </div>
          <div>
            <span>{{ $t("order-order_id") }}</span>
            <span> #{{ selectedItem?.orderId }}</span>
          </div>
          <div>{{ selectedItem?.createAt }}</div>
          <div>{{ selectedItem?.updatedAt }}</div>
        </div>
        <div
          class="v-text-sm"
          :class="isProfit(selectedItem) ? 'text-kline-up' : 'text-kline-down'"
        >
          {{ selectedItem?.factProfits }}
        </div>
      </div>

      <!-- Position holding content -->
      <div v-if="orderStatus == 0">
        <div
          class="bg-shadow v-py-20 v-px-6 v-rounded-10 v-mt-20 flex items-center justify-between"
        >
          <div class="v-mx-8">
            <div class="flex items-center justify-between">
              <span class="k-text v-pr-10">{{ $t("order-take_profit") }}</span>
              <van-stepper
                v-model="profitVal"
                :min="0"
                input-width="14.888vw"
                button-size="7.333vw"
              />
            </div>
            <div class="flex items-center justify-between v-mt-20">
              <span class="k-text v-pr-10">{{ $t("order-set_loss") }}</span>
              <van-stepper
                v-model="lossVal"
                :min="0"
                input-width="14.888vw"
                button-size="7.333vw"
              />
            </div>
          </div>
          <van-button
            size="small"
            round
            style="width: fit-content"
            class="btn-blue v-h-30 v-text-12 text-white v-border-0 v-mx-8"
            @click="setTargetPrice"
            >{{ $t("order-set_TPSL") }}</van-button
          >
        </div>
        <div class="flex items-center v-mt-8">
          <van-button
            size="normal"
            round
            class="btn-blue v-text-12 mx-auto v-mt-10 v-h-36 flex-1 v-rounded-xl v-mr-8 v-border-0"
            @click="closePosition"
            >{{ $t("order-close_position") }}
          </van-button>
        </div>
      </div>

      <!-- Pending order content -->
      <div v-if="orderStatus == 1">
        <div class="flex items-center v-mt-8">
          <van-button
            size="normal"
            round
            class="btn-blue v-text-12 mx-auto v-mt-10 v-h-36 flex-1 v-rounded-xl v-mr-8 v-border-0"
            @click="cancelOrder"
            >{{ $t("order-cancel_order") }}
          </van-button>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { store } from "@/assets/store/data.js";
import { showToast } from "/node_modules/vant";
import noRecord from "@/pages/components/common/noRecord.vue";

const props = defineProps({
  orderStatus: {
    type: Number,
    default: 0,
  },
});

const holdingList = store.value.holdingList;

//Props: 根据status传递数据
const holdingDataList = computed(() => {
  return holdingList.filter((record) => record.status === props.orderStatus);
});

const { t } = useI18n();
const router = useRouter();
const isSell = (item) => item.tradeStatus == t('public-text-sell');
const isProfit = (item) => item.factProfits > 0;
const profitVal = ref(0);
const lossVal = ref(0);
const loading = ref(true);
const finished = ref(false);
const emits = defineEmits(['toggle-popup']);

setTimeout(() => {
  holdingList.value;
  loading.value = false;
}, 600);

const onRefresh = () => {
  setTimeout(() => {
    const refreshedData = [];
    holdingList.value = refreshedData;
    finished.value = false;
    loading.value = false;
  }, 1000);
};

onMounted(() => {
  watch(showPopup, (newValue) => {
    if (!newValue) {
      emits('toggle-popup', false); // 通知父组件关闭弹出窗口
    }
  });
});
const showPopup = ref(false);
const selectedItem = ref(null);

const openOrderPopup = (item) => {
  selectedItem.value = item;
  showPopup.value = true;
  emits('toggle-popup', true); // 通知父组件打开弹出窗口
};

const closePopup = () => {
  showPopup.value = false;
};

const setTargetPrice = () => {
  //TODO: set value去哪里？？
  showToast({
    message: t("systemHint-set_successful"),
    icon: "success",
    zIndex: 3000,
  });
};
const closePosition = () => {
  //TODO: 点击这个btn后，该order的status会从0更新到2，数据去到history
  showToast({
    message: t("systemHint-operation_successful"),
    icon: "success",
  });
  showPopup.value = false;
};

const cancelOrder = () => {
  showToast({
    message: t("systemHint-operation_successful"),
    icon: "success",
  });
  showPopup.value = false;
};

// 监听orderId的变化来重置profitVal
watch(
  () => selectedItem.value?.orderId,
  (newOrderId, oldOrderId) => {
    if (newOrderId !== oldOrderId) {
      profitVal.value = 0;
      lossVal.value = 0;
    }
  }
);
</script>

<style scoped>
.trade-tag {
  border-radius: 1.2vw;
  overflow: hidden;
  transform: scale(0.9);
}
</style>
