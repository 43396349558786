<template>
    <div id="trade">
        <div v-if="curTab !== 2">
            <div class="v-text-12 v-border-0 border-b v-pb-16">
                <div v-if="curTab == 1"
                    class="flex justify-between items-center v-my-16 v-border-0 border-b">
                    <div class="text-color-6" style="min-width: 16vw">
                        {{ $t("trade-price") }}
                    </div>
                    <van-field v-model="priceVal" :placeholder="$t('trade-please_enter_price')"
                        style="font-size: 3.666vw; padding: 3vw 0" class="bg-main" input-align="right" type="number" />
                </div>

                <div class="flex justify-between items-center v-mt-14">
                    <span class="text-color-6">{{ $t("trade-multiplier") }}</span>
                    <div class="mult-picker flex items-center justify-end" @click="showMultiplierPicker = true">
                        <span class="k-text v-text-14 flex-1 text-center">{{ multiplierVal }}</span>
                        <van-icon name="arrow" class="icon-right" style="margin-right:1.6vw; font-size: 1.2rem;" />
                    </div>
                    <van-popup v-model:show="showMultiplierPicker" round position="bottom">
                        <van-picker
                            :columns="multiplierList"
                            :cancel-button-text="$t('public-text-cancel')"
                            :confirm-button-text="$t('public-text-confirm')"
                            @cancel="showMultiplierPicker = false"
                            @confirm="onMultiplierConfirm"
                        />
                    </van-popup>
                </div>
                <div class="flex justify-between items-center v-mt-14">
                    <van-checkbox label-position="left" v-model="lossChecked" shape="square">
                        <span class="text-color-6">{{ $t("order-set_loss") }}</span>
                    </van-checkbox>
                    <van-stepper v-model="lossVal" :min="0" :default-value="lossChecked ? openLoss : 0" input-width="28.66vw"
                        button-size="8vw" :disabled="!lossChecked" />
                </div>
                <div class="flex justify-between items-center v-mt-14">
                    <van-checkbox label-position="left" v-model="profitChecked" shape="square">
                        <span class="text-color-6">{{ $t("order-take_profit") }}</span>
                    </van-checkbox>
                    <van-stepper v-model="profitVal" :min="0" :default-value="profitChecked ? openProfit : 0"
                        input-width="28.66vw" button-size="8vw" :disabled="!profitChecked" />
                </div>
                <div class="flex justify-between items-center v-mt-14">
                    <span class="text-color-6">{{ $t("trade-lots_text") }}</span>
                    <van-stepper v-model="lotsVal" :min="0.01" :default-value="0.01" :step="0.01" input-width="28.66vw"
                        button-size="8vw" />
                </div>
            </div>
            <div class="v-text-12 v-py-16">
                <div class="flex items-center justify-between v-mt-14">
                    <div class="text-color-6">{{ $t("trade-each_lots") }}</div>
                    <div class="k-text">{{ `1 ${$t('public-text-lots_1')} = ${eachLots} ${keyName}` }}</div>                
                </div>
                <div class="flex items-center justify-between v-mt-14">
                    <div class="text-color-6">{{ $t("trade-estimated_handling_fee") }}</div>
                    <div class="k-text">{{ lotsVal }}</div>
                </div>
                <div class="flex items-center justify-between v-mt-14">
                    <div class="text-color-6">{{ $t("trade-estimated_margin") }}</div>
                    <div class="k-text">{{ marginVal }}</div>
                </div>
                <div class="flex items-center justify-between v-mt-14">
                    <div class="text-color-6">{{ $t("public-text-balance") }}</div>
                    <div class="k-text">{{ userInfo.Gold }}</div>
                </div>
            </div>

            <!-- 按钮：Buy & Sell -->
            <div class="flex text-center">
                <van-button round color="#fff" style="margin-right: 2vw"
                    class="v-h-36 flex-1 bg-kline-up v-rounded-xl v-border-0 v-text-14" :disabled="status === '1'"
                    @click="confirmBuyBtn">{{ $t("public-text-buy") }}
                </van-button>
                <van-button round color="#fff" style="margin-left: 2vw"
                    class="v-h-36 flex-1 bg-kline-down v-rounded-xl v-border-0 v-text-14" :disabled="status === '1'"
                    @click="confirmSellBtn">{{ $t("public-text-sell") }}
                </van-button>
            </div>
        </div>

        <div v-if="curTab == 2">
            <div class="v-mt-16 v-text-12 v-pb-16">
                <div class="text-color-6">{{ $t('order-select_period') }}</div>
                <div class="flex justify-between items-center">
                    <!-- 选择周期 -->
                    <div class="v-mt-4 base-shadow">
                        <van-field
                            v-model="periodVal"
                            readonly
                            left-icon="clock"
                            right-icon="arrow-down"
                            input-align="center"
                            class="full-border"
                            style="max-width: 28vw; width:fit-content; border-radius: 2.22vw;"
                            :placeholder="periodVal"
                            @click="showPeriodPicker = true"
                        />
                        <van-popup v-model:show="showPeriodPicker" round position="bottom">
                        <van-picker
                            round
                            :columns="periodList"
                            :cancel-button-text="$t('public-text-cancel')"
                            :confirm-button-text="$t('public-text-confirm')"
                            @cancel="showPeriodPicker = false"
                            @confirm="periodConfirm"
                        />
                        </van-popup>
                    </div>
                    <!-- 按钮 -->
                    <div class="btcButton flex v-text-16 text-center overflow-hidden v-rounded-8">
                        <div 
                            class="v-py-10 v-pr-6 cus-button button-1"
                            :class="selectedBtn == 0 ? 'active' : 'inactive'"
                            @click="selectBtn(0)"
                        >
                            {{ $t('order-btn_rise') }}
                        </div>
                        <div  
                            class="v-py-10 v-pl-6 cus-button button-2"
                            :class="selectedBtn == 1 ? 'active' : 'inactive'"
                            @click="selectBtn(1)"
                        >
                            {{ $t('order-btn_fall') }}
                        </div>
                    </div>
                </div>

                <div class="v-mt-8">
                    <div class="v-text-14 k-text">{{ $t('order-estimated_return')}}: {{ returnValue }}</div>
                </div>

                <div class="v-mt-16 text-color-6">{{ $t('order-purchase_amount') }}</div>
                <div class="flex justify-start items-center">
                    <!-- 选择金币 -->
                    <div class="v-mt-4 base-shadow v-mr-4">
                        <van-field
                            v-model="coinVal"
                            readonly
                            left-icon="gold-coin"
                            input-align="center"
                            class="full-border"
                            style="width: 28vw; border-radius: 2.22vw;"
                            :placeholder="coinVal"
                            @click="showCoinPicker = true"
                        />
                        <van-popup v-model:show="showCoinPicker" round position="bottom">
                        <van-picker
                            round
                            :columns="coinList"
                            :cancel-button-text="$t('public-text-cancel')"
                            :confirm-button-text="$t('public-text-confirm')"
                            @cancel="showCoinPicker = false"
                            @confirm="coinConfirm"
                        />
                        </van-popup>
                    </div>
                    <div class="v-mt-4 base-shadow v-w-full">
                        <van-field 
                            v-model="inputCoinVal"
                            type="number"
                            :placeholder="`>= 100 ` + coinVal"
                            class="full-border"
                            style="height: inherit; border-radius: 2.22vw;"
                        />
                    </div>
                </div>
                <div class="v-mt-8">
                    <div class="v-text-14 k-text">{{ $t('public-text-balance') }}: {{ userInfo.Gold }}</div>
                </div>

                <div class="v-w-full v-mt-22">
                    <van-button 
                        block
                        class="submit-btn v-border-0"
                        :disabled="status === '1'"
                        @click="submitBtn"
                    >
                        {{ $t('public-btn-submit') }}
                    </van-button>
                </div>
            </div>
        </div>

        <!-- 订单确认弹窗 -->
        <van-popup v-model:show="showConfirmPopup" position="bottom" round closeable>
            <div class="flex flex-col items-center">
                <div
                    class="flex justify-center v-border-4 border-kline-up v-rounded-half v-w-100 v-h-100 mx-auto items-center v-mt-36 v-pt-6">
                    <van-icon name="success" size="5rem" class="text-kline-up" />
                </div>
                <div class="k-text v-text-base v-my-20">
                    {{ $t("trade-your_order_has_been_confirmed") }}
                </div>
                <div class="flex justify-center w-[80%] v-h-40 mb-[14px]">
                    <van-button round class="btn-blue v-border-0 flex-1 v-h-40 v-text-14" @click="closePopup">
                        {{ $t("public-btn-confirmation") }}
                    </van-button>
                </div>
                <div class="flex justify-center">
                    <van-button 
                        icon="arrow" 
                        icon-position="right" 
                        class="text-kline-up"
                        style="
                            border: 0 !important;
                            font-size: 3.73333vw;
                            margin-bottom: 2vw;
                        " 
                        @click="navigateTo">{{ $t("trade-order_page") }}
                    </van-button>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script setup>
import { ref, onMounted, computed, watch, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { store } from "@/assets/store/data.js";
import { showToast } from "/node_modules/vant";

const props = defineProps({
    curTab: {
        type: Number,
        default: 0,
    },
    keyName: {
        type: String,
        required: true,
    },
    status: {
        type: String,
        default: "0",
    },
    eachLots: {
        type: String,
        required: true,
    },
    margin: {
        type: String,
        required: true,
    },
    openLoss: {
        type: String,
        required: true,
    },
    openProfit: {
        type: String,
        required: true,
    },
});
const storeA = useStore();
const { t } = useI18n();
const router = useRouter();
const userInfo = computed(() => storeA.getters["userInfo"]);
const isLogin = computed(() => storeA.getters["isLogin"]);
// curTab: 1
const priceVal = ref(null);
// curTab: 0 & 1
const lossChecked = ref(false);
const lossVal = ref(null);
const profitChecked = ref(false);
const profitVal = ref(null);
const lotsVal = ref(null);
const showConfirmPopup = ref(false);
const multiplierList = ref(store.value.multiplierList)
const multiplierVal = ref(multiplierList.value[0].text)
const showMultiplierPicker = ref(false)
// curTab: 2
const periodList = ref(store.value.periodList)
const periodVal = ref(periodList.value[0].text)
const showPeriodPicker = ref(false)
const selectedBtn = ref(0)
const returnValue = ref('20%')
const coinList = ref(store.value.coinList)
const coinVal = ref(coinList.value[0].text)
const showCoinPicker = ref(false)
const inputCoinVal = ref('')

onMounted(() => {
    lossVal.value = 0;
    profitVal.value = 0;
});

//计算Lots增减对应的Margin
const marginVal = computed(() => {
    return parseFloat(props.margin * (lotsVal.value * 100)).toFixed(0);
});

watchEffect(() => {
    if (lossChecked.value) {
        lossVal.value = props.openLoss;
    }

    if (profitChecked.value) {
        profitVal.value = props.openProfit;
    }
});

// 监听props变化，重置数据
watch(
    () => props.keyName,
    () => {
        priceVal.value = "";
        lossChecked.value = false;
        lossVal.value = null;
        profitChecked.value = false;
        profitVal.value = null;
        lotsVal.value = 0.01;
    }
);

const navigateTo = () => {
    router.push({ name: "OrderIndex" });
};

const closePopup = () => {
    showConfirmPopup.value = false
}

const confirmBuyBtn = () => {
    if (!isLogin.value) {
        router.push({ name: "LoginIndex" });
        return;
    }

    if (props.status === "1") {
        return;
    }

    if (isLogin.value && props.status === "0") {
        if (props.curTab == 1 && !priceVal.value) {
            showToast({
                message: t("systemHint-limit_trade_price_must_greater"),
                icon: "fail",
            });
        } else if (lossChecked.value && lossVal.value > props.openLoss) {
            showToast({
                message: t("systemHint-buy-stop_loss_price_higher_than_opening_price"),
                icon: "fail",
            });
        } else if (profitChecked.value && profitVal.value > props.openProfit) {
            showToast({
                message: t(
                    "systemHint-buy-stop_profit_price_higher_than_opening_price"
                ),
                icon: "fail",
            });
        } else {
            const isLossValid = lossChecked.value && lossVal.value <= props.openLoss;
            const isProfitValid =
                profitChecked.value && profitVal.value <= props.openProfit;

            if (
                (!lossChecked.value && !profitChecked.value) ||
                isLossValid ||
                isProfitValid
            ) {
                showConfirmPopup.value = true;
            }
        }
    }
};

const confirmSellBtn = () => {
    if (!isLogin.value) {
        router.push({ name: "LoginIndex" });
        return;
    }

    if (props.status === "1") {
        return;
    }

    if (isLogin.value && props.status === "0") {
        if (props.curTab == 1 && !priceVal.value) {
            showToast({
                message: t("systemHint-limit_trade_price_must_greater"),
                icon: "fail",
            });
        } else if (lossChecked.value && lossVal.value > props.openLoss) {
            showToast({
                message: t("systemHint-sell-stop_loss_price_lower_than_opening_price"),
                icon: "fail",
            });
        } else if (profitChecked.value && profitVal.value > props.openProfit) {
            showToast({
                message: t(
                    "systemHint-sell-stop_profit_price_lower_than_opening_price"
                ),
                icon: "fail",
            });
        } else {
            const isLossValid = lossChecked.value && lossVal.value <= props.openLoss;
            const isProfitValid =
                profitChecked.value && profitVal.value <= props.openProfit;

            if (
                (!lossChecked.value && !profitChecked.value) ||
                isLossValid ||
                isProfitValid
            ) {
                showConfirmPopup.value = true;
            }
        }
    }
};

const submitBtn = () => {
    if (!isLogin.value) {
        router.push({ name: "LoginIndex" });
        return;
    }

    if (props.status === "1") {
        return;
    }

    if (isLogin.value && props.status === "0") {
        if (!periodVal.value || !inputCoinVal.value) {
            showToast({
                message: t("trade-please_enter_price"),
                icon: "fail",
            });
        } else {
            showConfirmPopup.value = true;
            resetValue()
        }
    }
}

const onMultiplierConfirm = (val) => {
    multiplierVal.value = val.selectedOptions[0].text
    showMultiplierPicker.value = false
}

const periodConfirm = (val) => {
    periodVal.value = val.selectedOptions[0].text
    showPeriodPicker.value = false
}

const selectBtn = (idx) => {
    selectedBtn.value = idx
}

const coinConfirm = (val) => {
    coinVal.value = val.selectedOptions[0].text
    showCoinPicker.value = false
}

const resetValue = () => {
    periodVal.value = periodList.value[0].text
    selectedBtn.value = 0
    inputCoinVal.value = ''
}
</script>

<style scoped>
:deep(.van-field__right-icon > i) {
    font-size: 3.222vw !important;
}

/* :deep(.van-cell.van-field) {
    padding: 2vw !important;
} */

.cus-button {
    position: relative;
    width: 25.66667vw;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.button-1 {
    clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
    margin-right: -1.666vw;
}

.button-2 {
    clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%);
    margin-left: -1.666vw;
}

.mult-picker {
    width: 45.86667vw;
    height: 7.46667vw;
    display:flex;
    align-items: center;
    position: relative;
}

:deep(.van-field__body) {
    display: flex;
    justify-content: end;
}
</style>
