<template>
  <div style="min-height: 100vh; flex: 1;">
    <topNavBar :leftIcon="true" :headerTitle="$t('mine-inviteFriend')" />

    <div class="bg-general v-m-10 v-rounded-10 text-center v-py-10 base-shadow">
      <div class="text-color-7 v-text-12 text-center">
        {{ $t("public-inviteCode") }}
      </div>
      <div class="v-my-10 text-kline-up v-text-16">
        {{ inviteCode }}
      </div>
      <div class="flex justify-center">
        <img :src="getQRcode" width="160" height="160" />
      </div>
      <div class="k-text text-center v-text-12 v-my-10 v-px-20">{{ inviteLink }}</div>
      <div>
        <van-button
          class="btn-blue"
          style="height:9.6vw; padding:0 3.66vw; font-size: 3.222vw; border:0;"
          @click="copyContent"
          round
        >
          {{ $t("public-btn-copyLink") }}
        </van-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { showToast } from "/node_modules/vant";
import topNavBar from "@/pages/components/common/topNavBar.vue";

const { t } = useI18n();
const router = useRouter();
const inviteCode = ref("LLYEAF");
const getQRcode = ref(require("@/assets/image/qr-code.png"));
const inviteLink = ref(
  "https://m.texttrademo.com/#/register?invite_code=LLYEAF"
);

const copyContent = () => {
  const link = inviteLink.value
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(link);
    showToast({
        message: t("systemHint-copySuccess"),
        icon: "success",
    });
  } else {
    const textArea = document.createElement("textarea");
    textArea.value = link;
    textArea.style.position = "fixed";
    textArea.style.opacity = 0;
    document.body.appendChild(textArea);
    textArea.select();
    const successful = document.execCommand('copy');
    if (successful) {
        showToast({
            message: t("systemHint-copySuccess"),
            icon: "success",
        });
    } 
    document.body.removeChild(textArea);
  }
};
</script>

<style scoped></style>
