<template>
  <div class="v-mt-10 v-mx-10 base-shadow v-text-12 coin-list bg-general v-rounded-8">
    <van-swipe-cell :style="showDel ? showBtnStyle : hideBtnStyle">
      <div class="bg-general v-p-20 v-rounded-8">
        <div>
          <span class="pr-3 k-text">{{ $t("bind-bank-name") }}:</span>
          <span class="k-text">{{ items.currency }}</span>
        </div>
        <div class="v-mt-6">
          <span class="pr-3 k-text">{{ $t("bind-bank-address") }}:</span>
          <span class="k-text">{{ items.walletAddress }}</span>
        </div>
      </div>
      <template #right>
        <div
          class="flex items-center v-h-full bg-kline-down v-rounded-right" 
          @click="popDelete"
        >
          <van-button class="bg-kline-down v-border-0">
            <span class="text-white v-text-12">{{ $t("public-btn-delete") }}</span>
          </van-button>
        </div>
      </template>
    </van-swipe-cell>

    <van-dialog
      v-model:show="showDialog"
      :message="$t('popupHint-confirm_to_delete')"
      show-cancel-button
      :cancel-button-text="$t('public-text-cancel')"
      :confirm-button-text="$t('public-text-confirm')"
      @confirm="onDialogConfirm"
      @cancel="onDialogCancel"
    />
  </div>
</template>
<script setup>
import { ref } from "vue";

const props = defineProps({
  items: {
    type: Object,
    default: () => {},
  },
});
const showDel = ref(false);
const showBtnStyle = ref(
  "transform: translate3d(-75.2031px, 0px, 0px); transition-duration: 0.6s;"
);
const hideBtnStyle = ref(
  "transform: translate3d(0px, 0px, 0px); transition-duration: 0.6s;"
);

const showDialog = ref(false);
const popDelete = () => {
  showDialog.value = true;
};

const emits = defineEmits(["delete-currency"]);
const onDialogConfirm = () => {
  emits("delete-currency", props.items.id);
  showDialog.value = false;
};

const onDialogCancel = () => {
  showDialog.value = false;
};
</script>

<style scoped>
:deep(.van-button--default) {
  /* border: 0px #f23c48 solid; */
}

:deep(.van-swipe-cell__right) {
  transform: translate3d(101%, 0, 0);
}

</style>
