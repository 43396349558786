<template>
  <div style="min-height: 100vh; flex: 1">
    <topNavBar
      :leftIcon="true"
      :headerTitle="$t('wallet-bind-bank')"
      :iconName="'Plus'"
      :handleClick="bindBank"
    />

    <div class="list-main">
      <div class="" v-for="(item, index) in bankCardList" :key="index">
        <bankCardItem :items="item" />
      </div>
      <div class="v-h-full" v-if="bankCardList.length == 0">
        <noRecord />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { store } from "@/assets/store/data.js";
import topNavBar from "@/pages/components/common/topNavBar.vue";
import bankCardItem from "@/pages/components/mine/wallet/bindPages/bankCardItem.vue";
import noRecord from "@/pages/components/common/noRecord.vue";

const { t } = useI18n();
const router = useRouter();
const bankCardList = store.value.bankCardList;

const bindBank = () => {
  // router.push({ name: "AddBank" });
  router.push({ name: "WithdrawType", query: { type: "1" } });
};
</script>

<style scoped></style>
