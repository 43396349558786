<template>
  <div class="bg-main v-m-10 v-p-10">
    <div class="k-text v-mx-10 v-text-14 font-bold">{{ $t("withdraw-coin_withdrawal_record") }}</div>
      <van-list>
        <van-cell
          class="bg-general v-p-10 v-text-12 v-rounded-8 base-shadow v-mt-10"
          v-for="(item, index) in filteredWithdrawRecords"
          :key="index"
        >
          <div class="flex justify-between">
            <div class="flex-1 text-left">
              <div class="text-color-7">
                {{ $t("withdraw-coin_amount") }}
              </div>
              <div class="k-text">{{ item.withdrawAmount }}</div>
            </div>
            <div class="flex-1 text-center">
              <div class="text-color-7">
                {{ $t("withdraw-num_of_receipt") }}
              </div>
              <div class="k-text">{{ item.numofReceipt }}</div>
            </div>
            <div class="flex-1 text-right">
              <div class="text-color-7">
                {{ $t("deposit-recharge_unit") }}
              </div>
              <div class="k-text">{{ item.unit }}</div>
            </div>
          </div>

          <div class="flex justify-between">
            <div class="flex-1 text-left">
              <div class="text-color-7">
                {{ $t("withdraw-handling_fee") }}
              </div>
              <div class="k-text">{{ item.handlingFee }}</div>
            </div>
            <div class="flex-1 text-center">
              <div class="text-color-7">
                {{ $t("deposit-recharge_status") }}
              </div>
              <div class="k-text">{{ item.status }}</div>
            </div>
            <div class="flex-1 text-right">
              <div class="text-color-7">
                {{ $t("public-text-date") }}
              </div>
              <div class="k-text">{{ item.date }}</div>
            </div>
          </div>

          <div class="flex justify-between">
            <div class="flex-1 text-left">
              <div class="text-color-7">
                {{ $t("withdraw-coin_address") }}
              </div>
              <div class="k-text">{{ item.payeeAcc }}</div>
            </div>
          </div>

          <div class="">
            <div class="flex-1 text-left">
              <div class="text-color-7">
                {{ $t("public-text-remarks") }}
              </div>
              <div class="k-text">{{ item.remark }}</div>
            </div>
          </div>
        </van-cell>
      </van-list>
  </div>
</template>

<script setup>
import { reactive, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { store } from "@/assets/store/data.js";

const props = defineProps({
  curType: {
    type: String,
    required: true,
  },
});

const { t } = useI18n();
const router = useRouter();
const withdrawRecords = store.value.withdrawRecords;

//根据type传递数据
const filteredWithdrawRecords = computed(() => {
  return withdrawRecords.filter((record) => record.type === props.curType);
});
</script>

<style scoped>
</style>
