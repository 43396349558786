<template>
  <div style="min-height: 100vh; flex: 1">
    <topNavBar :leftIcon="true" :headerTitle="$t('title-detail')" />
    <div>
      <div class="v-p-10 break-all k-text">
        <div>
          <p>{{ news.title }}</p>
          <img :src="imageUrl" />
          <p>{{ news.content }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { store } from "@/assets/store/data.js";
import topNavBar from "@/pages/components/common/topNavBar.vue";

const props = defineProps({
  id: String,
});

const { t } = useI18n();
const router = useRouter();
const news = ref({});
const imageUrl = ref(null);
const newsList = store.value.newsList;

const fetchNewData = () => {
  const foundNews = newsList.find((item) => item.id === parseInt(props.id));
  if (foundNews) {
    news.value = foundNews;
    getImageUrl(foundNews.image)
      .then((url) => {
        imageUrl.value = url;
      })
      .catch((error) => {
        console.error(`Error loading image: ${error}`);
      });
  }
};

const getImageUrl = async (imageName) => {
  try {
    let url = await import(`@/assets/image/${imageName}`);
    return url.default;
  } catch (error) {
    console.error(`Error importing image: ${error}`);
    return null;
  }
};

onMounted(() => {
  fetchNewData();
});
</script>

<style scoped></style>
