<template>
    <div id="trade" style="min-height: 100vh; flex: 1;">
        <div class="bg-main" style="padding: 4.26vw 5.3vw 21.3vw 5.3vw;">
            <div class="TraTop bg-general base-shadow">
                <div class="flex items-center">
                    <div class="k-text" style="margin: 0 0.5vw 0 2.6vw;font-weight: 700; font-size: 4vw" @click="openLeftMenu">
                        {{ PageData.keyName }}
                        <van-icon name="play" style="transform: rotate(90deg); text-align: center; font-size: 3vw; margin-left: 0.7vw;"
                            size="12" />
                    </div>
                </div>
                <div :class="PageData.state == 0 ? 'text-kline-up' : 'text-kline-down'">
                    <span style="font-weight: 700;font-size: 4.8vw;">
                        {{ PageData.open }}
                    </span>
                    <span v-if="PageData.status == 0" style="margin: 0 5.3vw; font-size: 3.7333vw;">
                        {{ (PageData.state == 0 ? '' : '-') }}{{ PageData.Pct }}
                    </span>
                    <span v-else class="v-text-14 v-mx-20 text-color-5">{{ $t('public-text-close') }}</span>
                </div>
            </div>

            <div class="TraBtn">
                <div class="BtnTime" :class="TimeData == '1M' ? 'active' : ''" @click="CutTimeData('1M')">1M</div>
                <div class="BtnTime" :class="TimeData == '5M' ? 'active' : ''" @click="CutTimeData('5M')">5M</div>
                <div class="BtnTime" :class="TimeData == '15M' ? 'active' : ''" @click="CutTimeData('15M')">15M</div>
                <div class="BtnTime" :class="TimeData == '30M' ? 'active' : ''" @click="CutTimeData('30M')">30M</div>
                <div class="BtnTime" :class="TimeData == '1H' ? 'active' : ''" @click="CutTimeData('1H')">1H</div>
                <div class="BtnTime" :class="TimeData == '1D' ? 'active' : ''" @click="CutTimeData('1D')">1D</div>
            </div>

            <div class="flex justify-between v-py-10">
                <div style="width: 100%; height: 250px;">
                    <div id="KLine" style="width: 100%; height: 100%;"></div>
                </div>
            </div>

            <div class="button-container v-mt-22">
                <div class="btnTab flex v-rounded-base text-center overflow-hidden">
                    <!-- 市价 -->
                    <div 
                        class="flex-1 v-py-6"
                        :class="seletedTabBtn == 0 ? 'actived' : ''" 
                        @click="selectTab(0)">
                        {{ $t('trade-market_price') }}
                    </div>
                    <!-- 挂单 -->
                    <div 
                        class="flex-1 v-py-6" 
                        :class="seletedTabBtn == 1 ? 'actived' : ''" 
                        @click="selectTab(1)">
                        {{ $t('order-pending_orders') }}             
                    </div>
                    <!-- 秒合約 -->
                    <div 
                        class="flex-1 v-py-6" 
                        :class="seletedTabBtn == 2 ? 'actived' : ''" 
                        @click="selectTab(2)">
                        {{ $t('trade-quick_trade') }}                   
                    </div>
                </div>
                <div v-if="seletedTabBtn == 0">
                    <TradeOrder 
                        :curTab="0" 
                        :keyName="PageData.keyName"
                        :status="PageData.status"
                        :eachLots="PageData.eachLots"
                        :margin="PageData.margin"
                        :openLoss="PageData.openLoss"
                        :openProfit="PageData.openProfit"
                        :key="componentKey"
                    />
                </div>
                <div v-if="seletedTabBtn == 1">
                    <TradeOrder 
                        :curTab="1"
                        :keyName="PageData.keyName"
                        :status="PageData.status"
                        :eachLots="PageData.eachLots"
                        :margin="PageData.margin"
                        :openLoss="PageData.openLoss"
                        :openProfit="PageData.openProfit"
                        :key="componentKey"
                    />
                </div>
                <div v-if="seletedTabBtn == 2">
                    <TradeOrder 
                        :curTab="2"
                        :keyName="PageData.keyName"
                        :status="PageData.status"
                        :eachLots="PageData.eachLots"
                        :margin="PageData.margin"
                        :openLoss="PageData.openLoss"
                        :openProfit="PageData.openProfit"
                        :key="componentKey"
                    />
                </div>
            </div>
        </div>

        <!-- 左侧菜单 -->
        <van-popup
            v-model:show="showTradeSideMenu"
            position="left"
            :style="{ width: '64%', height: '100%' }"
        >
            <div 
                class="flex justify-between v-py-20 v-px-10 v-border-0 customBottomLine v-pb-16"
                v-for="(item, idx) in $lineData"
                :key="idx"
                @click="enterSelectedTrade(item)"
            >
                <div class="k-text">{{ item.keyName }}</div>
                <div :class="compareValue(item)">{{ item.open }}</div>
            </div>
        </van-popup>

        <tabBar />
    </div>
</template>

<script setup>
import { onMounted, ref, watch, getCurrentInstance, nextTick } from "vue";
import tabBar from "@/pages/components/common/tab-bar.vue";
import { useRouter, useRoute } from 'vue-router';
import { getKData, ViveKLine, updateData, applyNewData } from "@/api/chart";
import TradeOrder from "@/pages/tabBarPages/tradeOrder.vue"

const { appContext } = getCurrentInstance()
const route = useRoute();
const router = useRouter()
const keyName = route.params.KeyName ? route.params.KeyName : '';
const PageData = ref(getKData(keyName));
const TimeData = ref('1M')
const selectedTrade = ref(null);
const seletedTabBtn = ref(0);
const componentKey = ref(0);
const showTradeSideMenu = ref(false)
const lineData = appContext.config.globalProperties.$lineData //调用chart.js的lineData

onMounted(()=>{
    ViveKLine('KLine')
    setTimeout(() => {
        updateData('KLine')
    }, 1000);
})

watch(() => selectedTrade.value, (newVal) => {
    PageData.value = newVal
    applyNewData('KLine')
},
    { deep: true })

const CutTimeData = (open) => {
    TimeData.value = open
}

// 收盘 > 开盘（涨）| 开盘 > 收盘（跌）
const compareValue = (item) => {
    return (item.open > item.close) ? 'text-kline-down' : 'text-kline-up'
}

const selectTab = (index) => {
  seletedTabBtn.value = index;
};

const openLeftMenu = () => {
    showTradeSideMenu.value = true
}

const enterSelectedTrade = (item) => {
    PageData.value = item
    nextTick(() => {
        applyNewData('KLine')
    })
    showTradeSideMenu.value = false
}
</script>

<style scoped>
.TraTop{
    font-size: 3.2vw;
    border-radius: 2.6vw;
    display: flex;
    justify-content: space-between;
    height: 6.777vw;
    align-items: center;
}

.TraBtn{
    display: flex;
    justify-content: space-around;
    padding: 2.444vw 0;
}

.BtnTime{
    /* background: #eeeeee; */
    padding: .053333vw 0;
    width: 12.26667vw;
    border-radius: 3.2vw;
    font-size: 3.2vw;
    text-align: center;
}

/* .BtnTime.active{
    background: #0166fc !important;
    color: white !important;
} */

</style>